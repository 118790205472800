// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/bookingsPage/bookingsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import BookingActionMenu from "../../menus/bookingsPage/bookingActionMenu";
import TimeSlotToRoomActionMenu from "../../menus/bookingsPage/timeSlotToRoomActionMenu";
// Modals
import BookingActionModal from "../../modals/bookingsPage/bookingActionModal/bookingActionModal";
import ModalContainer from "../../modals/modalContainer";
import SearchPatientModal from "../../modals/search/searchPatientModal";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";
// Tables
import RoomBox from "./roomBox";
import TimeSlotBox from "./timeSlotBox";
import TimeSlotToRoomBox from "./timeSlotToRoomBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function TimeSlotToRoomTable(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    bookingActionSnackbarText,
    bookingActionSnackbarType,
    hoveredRoomId,
    isEditMode,
    isHoveredRoomCapable,
    numOfAffectedTimeSlots,
    rooms,
    selectedBookingId,
    selectedBookingStatusTypeId,
    selectedRoomId,
    selectedRoomCapableTherapyIdsArr,
    selectedTherapyId,
    selectedTimeSlotId,
    showBookingActionSnackbar,
    therapies,
    timeSlotWithBookings,
    // Set States
    setHoveredRoomId,
    setHoveredRoomCapableTherapyIdsArr,
    setIsEditMode,
    setNumOfAffectedTimeSlots,
    setSelectedBookingId,
    setSelectedBookingStatusTypeId,
    setSelectedRoomId,
    setSelectedRoomCapableTherapyIdsArr,
    setSelectedTherapyId,
    setSelectedTimeSlotId,
    // Events
    onBookingActionSnackbarClosed,
    // Functions
    cancelBooking,
    clearAll,
    createBooking,
    editBooking,
    validateBooking,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Dialog
  const [bookingActionDialogText, setBookingActionDialogText] = useState("");
  const [bookingActionDialogType, setBookingActionDialogType] = useState(null);
  const [showBookingActionDialog, setShowBookingActionDialog] = useState(false);
  // Hover
  const [hoveredTimeSlotIdsArr, setHoveredTimeSlotIdsArr] = useState([]);
  // Menu
  const [showBookingActionMenu, setShowBookingActionMenu] = useState(null);
  const [showTimeSlotToRoomActionMenu, setShowTimeSlotToRoomActionMenu] =
    useState(null);
  // Modals
  const [bookingActionModalBookingId, setBookingActionModalBookingId] =
    useState(null);
  const [showBookingActionModal, setShowBookingActionModal] = useState(false);
  const [showSearchPatientModal, setShowSearchPatientModal] = useState(false);

  // Handle States
  const isRelocationEnabled = selectedBookingStatusTypeId
    ? // 已完成
      selectedBookingStatusTypeId !== 3
    : true;

  // Events
  // Events - Box
  const onItemClicked = async (timeSlotId, roomId, bookingId) => {
    if (selectedTherapyId) {
      const validateResults = await validateBooking(
        roomId,
        timeSlotId,
        selectedTherapyId
      );

      if (!validateResults) {
        return;
      }

      if (selectedBookingId) {
        editBooking(roomId, timeSlotId);
      } else {
        const roomItem = rooms.find((item) => item.id === roomId);

        // Set States
        setSelectedTimeSlotId(timeSlotId);
        setSelectedRoomId(roomId);
        setSelectedRoomCapableTherapyIdsArr(
          roomItem ? roomItem.therapyIds : []
        );

        displaySearchPatientModal();
      }
    } else {
      // Set States
      setBookingActionModalBookingId(bookingId);

      displayBookingActionModal();
    }
  };

  const onItemRightClicked = (
    timeSlotId,
    roomId,
    bookingId,
    bookingStatusTypeId,
    bookingTherapyId,
    bookingNumOfTimeSlot,
    currentTarget
  ) => {
    if (bookingId) {
      // Set States
      setBookingActionModalBookingId(bookingId);
      setNumOfAffectedTimeSlots(bookingNumOfTimeSlot);
      setSelectedBookingId(bookingId);
      setSelectedBookingStatusTypeId(bookingStatusTypeId);
      setSelectedTherapyId(bookingTherapyId);

      setShowBookingActionMenu(currentTarget);
    } else {
      const roomItem = rooms.find((item) => item.id === roomId);

      // Set States
      setSelectedTimeSlotId(timeSlotId);
      setSelectedRoomId(roomId);
      setSelectedRoomCapableTherapyIdsArr(roomItem ? roomItem.therapyIds : []);

      setShowTimeSlotToRoomActionMenu(currentTarget);
    }
  };

  // Events - Dialogs
  const onBookingActionDialogCanceled = () => {
    clearAll();

    // Set States
    setShowBookingActionDialog(false);
  };

  const onBookingActionDialogConfirmed = () => {
    switch (bookingActionDialogType) {
      case "CancelBooking":
        cancelBooking();
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (timeSlotId, roomId) => {
    // Set States
    if (numOfAffectedTimeSlots) {
      let timeSlotIdsArrTemp = [];

      for (let i = 0; i < numOfAffectedTimeSlots; i++) {
        let top, bottom;

        if (numOfAffectedTimeSlots === 1) {
          top = true;
          bottom = true;
        } else if (i === 0) {
          top = true;
          bottom = false;
        } else if (i === numOfAffectedTimeSlots - 1) {
          top = false;
          bottom = true;
        } else {
          top = false;
          bottom = false;
        }

        timeSlotIdsArrTemp.push({ id: timeSlotId + i, top, bottom });
      }

      setHoveredTimeSlotIdsArr(timeSlotIdsArrTemp);
    } else {
      setHoveredTimeSlotIdsArr([{ id: timeSlotId, top: true, bottom: true }]);
    }

    const roomItem = rooms.find((item) => item.id === roomId);

    // Set States
    setHoveredRoomId(roomId);
    setHoveredRoomCapableTherapyIdsArr(roomItem ? roomItem.therapyIds : []);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredTimeSlotIdsArr([]);
    setHoveredRoomId(null);
    setHoveredRoomCapableTherapyIdsArr([]);
  };

  // Events - Menu
  const onBookingActionMenuItemClikced = (actionType) => {
    switch (actionType) {
      case "Edit":
        displayBookingActionModal();
        break;
      case "Relocate":
        // Set States
        setIsEditMode(true);
        break;
      default:
        break;
    }
  };

  const onTimeSlotToRoomActionMenuClosed = () => {
    clearAll();

    // Set States
    setShowTimeSlotToRoomActionMenu(null);
  };

  const onTimeSlotToRoomActionMenuItemClicked = async (itemId) => {
    const validateResults = await validateBooking(
      selectedRoomId,
      selectedTimeSlotId,
      itemId
    );

    if (!validateResults) {
      return;
    }

    // Set States
    setSelectedTherapyId(itemId);
    setShowTimeSlotToRoomActionMenu(null);

    displaySearchPatientModal();
  };

  // Events - Modal
  const onBookingActionModalClosed = () => {
    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());

    // Set States
    setShowBookingActionModal(false);
  };

  const onSearchPatientModalItemClicked = (itemId) => {
    createBooking(itemId);

    onSearchPatientModalClosed();
  };

  const onSearchPatientModalClosed = () => {
    clearAll();

    // Set States
    setShowSearchPatientModal(false);
  };

  // Functions
  // Functions - Normal
  const displayBookingActionDialog = (bookingActionType) => {
    // Set States
    setBookingActionDialogType(bookingActionType);

    switch (bookingActionType) {
      case "CancelBooking":
        setBookingActionDialogText("確認要取消 預約 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(true);
  };

  const displayBookingActionModal = () => {
    // Set States
    setShowBookingActionModal(true);
  };

  const displaySearchPatientModal = () => {
    // Set States
    setShowSearchPatientModal(true);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onBookingActionDialogCanceled}
        onDialogConfirmed={onBookingActionDialogConfirmed}
        // States
        dialogText={bookingActionDialogText}
        showDialog={showBookingActionDialog}
      />
      {/* Menu */}
      <BookingActionMenu
        // States
        isRelocationEnabled={isRelocationEnabled}
        showBookingActionMenu={showBookingActionMenu}
        // Set States
        setShowBookingActionMenu={setShowBookingActionMenu}
        // Events
        onBookingActionMenuItemClikced={onBookingActionMenuItemClikced}
        // Functions
        clearAll={clearAll}
        displayBookingActionDialog={displayBookingActionDialog}
      />
      <TimeSlotToRoomActionMenu
        // Events
        onMenuClosed={onTimeSlotToRoomActionMenuClosed}
        onMenuItemClicked={onTimeSlotToRoomActionMenuItemClicked}
        // States
        selectedRoomCapableTherapyIdsArr={selectedRoomCapableTherapyIdsArr}
        showTimeSlotToRoomActionMenu={showTimeSlotToRoomActionMenu}
        therapies={therapies}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onBookingActionModalClosed}
        // States
        showModal={showBookingActionModal}
      >
        <BookingActionModal
          // Events
          onModalClosed={onBookingActionModalClosed}
          // States
          bookingId={bookingActionModalBookingId}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onSearchPatientModalClosed}
        // States
        showModal={showSearchPatientModal}
      >
        <SearchPatientModal
          // Events
          onItemClicked={onSearchPatientModalItemClicked}
        />
      </ModalContainer>
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onBookingActionSnackbarClosed}
        // States
        showSnackbar={showBookingActionSnackbar}
        snackbarText={bookingActionSnackbarText}
        snackbarType={bookingActionSnackbarType}
      />
      <Table stickyHeader>
        <TableHead>
          {rooms && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}></TableCell>
              {rooms.map((therapyItem) => (
                <TableCell
                  align="center"
                  key={therapyItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroudColorLight
                        : stylesConfig.contentBackgroudColorDark,
                  }}
                >
                  <RoomBox
                    // States
                    hoveredRoomId={hoveredRoomId}
                    item={therapyItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {timeSlotWithBookings && (
          <TableBody>
            {timeSlotWithBookings.map((timeSlotItem, index) => (
              <TableRow key={timeSlotItem.id} className={classes.timeTable}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <TimeSlotBox
                    // States
                    index={index}
                    item={timeSlotItem}
                  />
                </TableCell>
                {timeSlotItem.timeSlotToRooms.map((timeSlotToRoomItem) => (
                  <TableCell
                    align="center"
                    key={timeSlotToRoomItem.room_id}
                    scope="row"
                  >
                    <TimeSlotToRoomBox
                      // States
                      hoveredTimeSlotIdsArr={hoveredTimeSlotIdsArr}
                      hoveredRoomId={hoveredRoomId}
                      isEditMode={isEditMode}
                      isHoveredRoomCapable={isHoveredRoomCapable}
                      item={timeSlotToRoomItem}
                      selectedBookingId={selectedBookingId}
                      selectedRoomId={selectedRoomId}
                      selectedTherapyId={selectedTherapyId}
                      selectedTimeSlotId={selectedTimeSlotId}
                      timeSlotId={timeSlotItem.id}
                      timeSlotStartTime={timeSlotItem.start_time}
                      // Events
                      onItemClicked={onItemClicked}
                      onItemRightClicked={onItemRightClicked}
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default TimeSlotToRoomTable;
