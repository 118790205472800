const initialState = {
  patientId: null,
  formSubmitCount: 0,
  tabItem: "Bookings",
};

export const patientProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_PATIENT_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_PATIENT_PROFILE_PAGE_PATIENT_CHANGE":
      const { patientId } = action;
      return {
        ...state,
        patientId,
      };
    case "ON_PATIENT_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
