// Components
// Dialogs
import AlertDialog from "../../../dialogs/alertDialog";
// Modals
import BookingActionModalBookingInfo from "./bookingActionModalBookingInfo";
import BookingActionModalPatientInfo from "./bookingActionModalPatientInfo";
import ModalContainer from "../../modalContainer";
import SearchActionModal from "../../search/searchActionModal";

// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Fetches
import {
  editBookingStatusTypeAndHandledByFetch,
  getBookingDetailsByIdFetch,
} from "../../../../fetches/bookingFetches";
import { getBookingActionFormOptionsFetch } from "../../../../fetches/formOptionFetches";
import { getAllStaffsByShopIdAndDateFetch } from "../../../../fetches/staffFetches";
import {
  getAllTimeSlotsByShopIdAndDateFetch,
  getNearestTimeSlotsByShopIdAndDateFetch,
} from "../../../../fetches/timeSlotFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function BookingActionModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    bookingId,
  } = props;

  // Redux Store
  const date = useSelector((state) => state.bookingsPage.date);
  const shopId = useSelector((state) => state.bookingsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Data
  const [additionalStaffIds, setAdditionalStaffIds] = useState([]);
  const [bookingDetails, setBookingDetails] = useState(null);
  // Dialog
  const [bookingActionDialogText, setBookingActionDialogText] = useState("");
  const [bookingActionDialogType, setBookingActionDialogType] = useState(null);
  const [showBookingActionDialog, setShowBookingActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Modals
  const [showSearchActionModal, setShowSearchActionModal] = useState(false);
  // Option Fields
  const [bookingStatusTypeField, setBookingStatusTypeField] = useState(null);
  const [completeTimeSlotField, setCompleteTimeSlotField] = useState(null);
  const [handledByField, setHandledByField] = useState(null);
  // Options
  const [bookingStatusTypeOptions, setBookingStatusTypeOptions] = useState([]);
  const [completeTimeSlotOptions, setCompleteTimeSlotOptions] = useState([]);
  const [handledByOptions, setHandledByOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Handle States
  const dateVar = new Date(date);
  const staffIdsArr = handledByOptions[0]
    ? handledByOptions.map((item) => item.id)
    : [];

  // Events
  // Events - Dialogs
  const onBookingActionDialogCanceled = () => {
    // Set States
    setShowBookingActionDialog(false);
  };

  const onBookingActionDialogConfirmed = () => {
    switch (bookingActionDialogType) {
      case "EditBooking":
        editBookingStatusTypeAndHandledBy();
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(false);
  };

  // Events - Fields
  const onGetNearestTmeSlotBtnClicked = () => {
    getNearestTimeSlotsByShopIdAndDate();
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "bookingStatusTypeField":
        setBookingStatusTypeField(value);
        break;
      case "completeTimeSlotField":
        setCompleteTimeSlotField(value);
        break;
      case "handledByField":
        setHandledByField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked("EditBooking");
    }
  };

  const onSearchOtherStaffsBtnClicked = () => {
    displaySearchActionModal();
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!bookingStatusTypeField) {
      addToErrorFields("bookingStatusTypeField", "請先填寫 預約狀態");
      isError = true;
    }

    // 已完成
    if (bookingStatusTypeField.id === 3) {
      if (!handledByField) {
        addToErrorFields("handledByField", "請先填寫 負責員工");
        isError = true;
      }

      if (!completeTimeSlotField) {
        addToErrorFields("completeTimeSlotField", "請先填寫 完成時間");
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayBookingActionDialog("EditBooking");
  };

  // Events - Modal
  const onSearchActionModalClosed = () => {
    // Set States
    setShowSearchActionModal(false);
  };

  const onSearchActionModalItemClicked = (
    itemId,
    itemType,
    metaDataCategoryId,
    metaDataId,
    metaDataType
  ) => {
    switch (itemType) {
      case "Staff":
        addStaffToAdditionalStaffIds(itemId);
        break;
      default:
        break;
    }

    onSearchActionModalClosed();
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const addStaffToAdditionalStaffIds = (staffId) => {
    setAdditionalStaffIds((currentState) => [...currentState, staffId]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearAdditionalStaffIds = () => {
    // Set States
    setAdditionalStaffIds([]);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayBookingActionDialog = (bookingActionType) => {
    // Set States
    setBookingActionDialogType(bookingActionType);

    switch (bookingActionType) {
      case "EditBooking":
        setBookingActionDialogText("確認要編輯 預約 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(true);
  };

  const displaySearchActionModal = () => {
    // Set States
    setShowSearchActionModal(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editBookingStatusTypeAndHandledBy = async () => {
    const results = await editBookingStatusTypeAndHandledByFetch(
      token,
      bookingId,
      bookingStatusTypeField ? bookingStatusTypeField.id : null,
      handledByField ? handledByField.id : null,
      bookingStatusTypeField &&
        bookingStatusTypeField.id === 3 &&
        completeTimeSlotField
        ? completeTimeSlotField.id
        : null
    );

    if (results.success) {
      onModalClosed();
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      showFormAlert("warning", results.message);
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions = Queries
  const getAllStaffsByShopIdAndDate = async () => {
    const results = await getAllStaffsByShopIdAndDateFetch(
      token,
      shopId,
      dateVar,
      additionalStaffIds
    );

    // Set States
    setHandledByOptions(results.staffs ? results.staffs : []);

    if (results.staffs && additionalStaffIds[0]) {
      // Set States
      setHandledByField(
        results.staffs.find(
          (item) =>
            item.id === additionalStaffIds[additionalStaffIds.length - 1]
        )
      );
    }
  };

  const getBookingDetailsById = async () => {
    const results = await getBookingDetailsByIdFetch(token, bookingId);

    if (results.bookingDetails) {
      results.bookingDetails.date_display = results.bookingDetails.date
        ? moment(results.bookingDetails.date).format("YYYY年 MM月 DD日")
        : null;

      results.bookingDetails.start_time = results.bookingDetails.start_time
        ? moment(results.bookingDetails.start_time).format("a h:mm")
        : null;
    }

    // Set States
    setBookingDetails(results.bookingDetails ? results.bookingDetails : null);

    if (results.bookingDetails) {
      const { booking_status_type_id, handled_by, end_time_slot_id } =
        results.bookingDetails;

      // Set States
      setBookingStatusTypeField(
        bookingStatusTypeOptions.find(
          (item) => item.id === booking_status_type_id
        )
      );
      setHandledByField(
        handled_by
          ? handledByOptions.find((item) => item.id === handled_by)
          : null
      );
      setCompleteTimeSlotField(
        end_time_slot_id
          ? completeTimeSlotOptions.find((item) => item.id === end_time_slot_id)
          : null
      );
    }

    clearErrorFields();
  };

  const getBookingActionFormOptions = async () => {
    const results = await getBookingActionFormOptionsFetch(token);

    // Set States
    setBookingStatusTypeOptions(
      results.bookingStatusTypes ? results.bookingStatusTypes : []
    );
  };

  const getAllTimeSlotsByShopIdAndDate = async () => {
    const results = await getAllTimeSlotsByShopIdAndDateFetch(
      token,
      shopId,
      dateVar
    );

    if (results.timeSlots) {
      for (let item of results.timeSlots) {
        item.end_time = item.end_time
          ? moment(item.end_time).format("HH:mm")
          : null;
      }
    }

    // Set States
    setCompleteTimeSlotOptions(results.timeSlots ? results.timeSlots : []);
  };

  const getNearestTimeSlotsByShopIdAndDate = async () => {
    const results = await getNearestTimeSlotsByShopIdAndDateFetch(
      token,
      shopId,
      date
    );

    // Set States
    setCompleteTimeSlotField(
      completeTimeSlotOptions && results.nearestTimeSlotId
        ? completeTimeSlotOptions.find(
            (item) => item.id === results.nearestTimeSlotId
          )
        : null
    );
  };

  // Life Cycle
  useEffect(() => {
    clearAdditionalStaffIds();

    getBookingActionFormOptions();
    getAllTimeSlotsByShopIdAndDate();
  }, []);

  useEffect(() => {
    getAllStaffsByShopIdAndDate();
  }, [additionalStaffIds]);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && bookingStatusTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [bookingStatusTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (bookingId) {
        getBookingDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, bookingId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onBookingActionDialogCanceled}
        onDialogConfirmed={onBookingActionDialogConfirmed}
        // States
        dialogText={bookingActionDialogText}
        showDialog={showBookingActionDialog}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSearchActionModalClosed}
        // States
        showModal={showSearchActionModal}
      >
        <SearchActionModal
          // Events
          onItemClicked={onSearchActionModalItemClicked}
          // States
          exceptionIdsArr={staffIdsArr}
          paramsArr={["Booking"]}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            編輯預約
          </Typography>
        </div>
      </div>
      {/* Content */}
      {bookingDetails ? (
        <div>
          <BookingActionModalBookingInfo
            // States
            item={bookingDetails}
          />
          <BookingActionModalPatientInfo
            // States
            item={bookingDetails}
          />
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {handledByOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    `${option.staff_code} - ${option.full_name_en}`
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("handledByField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={handledByOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("handledByField")}
                      helperText={getErrorFieldMessage("handledByField")}
                      label="負責員工"
                      variant="outlined"
                    />
                  )}
                  value={handledByField}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSearchOtherStaffsBtnClicked}
                variant="text"
                sx={formSubmitBtnStyles}
              >
                選擇其他員工
              </Button>
            </Grid>
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {bookingStatusTypeOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option.booking_status_type_name_ch
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("bookingStatusTypeField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={bookingStatusTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("bookingStatusTypeField")}
                      helperText={getErrorFieldMessage(
                        "bookingStatusTypeField"
                      )}
                      label="預約狀態"
                      variant="outlined"
                    />
                  )}
                  value={bookingStatusTypeField}
                />
              )}
            </Grid>
            {/* 已完成 */}
            {bookingStatusTypeField && bookingStatusTypeField.id === 3 && (
              <>
                <Grid
                  className={classes.formAutoCompleteContainer}
                  item
                  xs={12}
                >
                  {completeTimeSlotOptions && (
                    <Autocomplete
                      disablePortal
                      getOptionLabel={(option) => option.end_time}
                      onChange={(event, value) =>
                        onInputFieldChange("completeTimeSlotField", value)
                      }
                      onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                      options={completeTimeSlotOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={checkIsFieldError("completeTimeSlotField")}
                          helperText={getErrorFieldMessage(
                            "completeTimeSlotField"
                          )}
                          label="完成時間"
                          variant="outlined"
                        />
                      )}
                      value={completeTimeSlotField}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.formSubmitBtnContainer}>
                  <Button
                    onClick={onGetNearestTmeSlotBtnClicked}
                    variant="text"
                    sx={formSubmitBtnStyles}
                  >
                    選擇現在時間
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                修改
              </Button>
            </Grid>
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有預約資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default BookingActionModal;
