export function onAreaChange(areaId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_AREA_CHANGE",
    areaId,
  };
}

export function onAreaItemChange(areaItem) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_AREA_ITEM_CHANGE",
    areaItem,
  };
}

export function onBrandChange(brandId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onDateChange(date) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_DATE_CHANGE",
    date,
  };
}

export function onDayChange(dayId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_DAY_CHANGE",
    dayId,
  };
}

export function onDistrictChange(districtId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_DISTRICT_CHANGE",
    districtId,
  };
}

export function onDistrictItemChange(districtItem) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_DISTRICT_ITEM_CHANGE",
    districtItem,
  };
}

export function onMonthChange(month) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_MONTH_CHANGE",
    month,
  };
}

export function onRoomChange(roomId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_ROOM_CHANGE",
    roomId,
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTherapyChange(therapyId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_THERAPY_CHANGE",
    therapyId,
  };
}

export function onTherapyItemChange(therapyItem) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_THERAPY_ITEM_CHANGE",
    therapyItem,
  };
}

export function onTimeSlotChange(timeSlotId) {
  return {
    type: "ON_CREATE_BOOKING_PAGE_TIME_SLOT_CHANGE",
    timeSlotId,
  };
}
