// Components
// Menus
import RoomToTherapyActionMenu from "../../menus/therapiesPage/roomToTherapyActionMenu";
// Tables
import TherapyBox from "./therapyBox";
import RoomBox from "./roomBox";
import RoomToTherapyBox from "./roomToTherapyBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// React
import { useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RoomToTherapyTable(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    editedRoomToTherapies,
    hoveredRoomId,
    isSelectMode,
    roomWithTherapies,
    selectedRoomToTherapies,
    therapies,
    // Set States
    setHoveredRoomId,
    setIsSelectMode,
    setSelectedRoomToTherapies,
    // Functions
    createOrEditRoomToTherapies,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Hover
  const [hoveredTherapyId, setHoveredTherapyId] = useState(null);
  // Menu
  const [showRoomToTherapyActionMenu, setShowRoomToTherapyActionMenu] =
    useState(null);

  // Events
  // Events - Box
  const onItemRightClicked = (roomId, therapyId, currentTarget) => {
    // Set States
    if (selectedRoomToTherapies.length <= 1) {
      setSelectedRoomToTherapies([{ roomId, therapyId }]);
    }

    // Set States
    setShowRoomToTherapyActionMenu(currentTarget);
  };

  const onMouseDown = (roomId, therapyId, event) => {
    if (event.button === 0) {
      // Set States
      setIsSelectMode(true);

      if (roomId && therapyId) {
        // Set States
        setSelectedRoomToTherapies((currentState) =>
          currentState.some(
            (item) => item.roomId === roomId && item.therapyId === therapyId
          )
            ? currentState.filter(
                (item) => item.roomId !== roomId || item.therapyId !== therapyId
              )
            : [...currentState, { roomId, therapyId }]
        );
      }
    }
  };

  // Events - Hover
  const onMouseEnterItem = (roomId, therapyId) => {
    // Set States
    setHoveredRoomId(roomId);
    setHoveredTherapyId(therapyId);

    if (isSelectMode) {
      // Set States
      setSelectedRoomToTherapies((currentState) =>
        currentState.some(
          (item) => item.roomId === roomId && item.therapyId === therapyId
        )
          ? currentState.filter(
              (item) => item.roomId !== roomId || item.therapyId !== therapyId
            )
          : [...currentState, { roomId, therapyId }]
      );
    }
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredRoomId(null);
    setHoveredTherapyId(null);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      {/* Menu */}
      <RoomToTherapyActionMenu
        // States
        showRoomToTherapyActionMenu={showRoomToTherapyActionMenu}
        // Set States
        setShowRoomToTherapyActionMenu={setShowRoomToTherapyActionMenu}
        // Functions
        createOrEditRoomToTherapies={createOrEditRoomToTherapies}
      />
      <Table stickyHeader>
        <TableHead>
          {therapies && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}></TableCell>
              {therapies.map((therapyItem) => (
                <TableCell
                  align="center"
                  key={therapyItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroudColorLight
                        : stylesConfig.contentBackgroudColorDark,
                  }}
                >
                  <TherapyBox
                    // States
                    hoveredTherapyId={hoveredTherapyId}
                    item={therapyItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {roomWithTherapies && (
          <TableBody>
            {roomWithTherapies.map((roomItem, index) => (
              <TableRow key={roomItem.id}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <RoomBox
                    // States
                    hoveredRoomId={hoveredRoomId}
                    index={index}
                    item={roomItem}
                  />
                </TableCell>
                {roomItem.roomToTherapies.map((roomToTherapyItem) => (
                  <TableCell
                    align="center"
                    key={roomToTherapyItem.therapy_id}
                    scope="row"
                  >
                    <RoomToTherapyBox
                      // States
                      editedRoomToTherapies={editedRoomToTherapies}
                      item={roomToTherapyItem}
                      roomId={roomItem.id}
                      selectedRoomToTherapies={selectedRoomToTherapies}
                      // Events
                      onItemRightClicked={onItemRightClicked}
                      onMouseDown={onMouseDown}
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default RoomToTherapyTable;
