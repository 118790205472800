// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  createRoomFetch,
  editRoomFetch,
  getRoomDetailsByIdFetch,
} from "../../../fetches/roomFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function RoomActionModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    roomId,
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.therapiesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [roomActionDialogText, setRoomActionDialogText] = useState("");
  const [roomActionDialogType, setRoomActionDialogType] = useState(null);
  const [showRoomActionDialog, setShowRoomActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [roomName, setRoomName] = useState("");

  // Events
  // Events - Dialogs
  const onRoomActionDialogCanceled = () => {
    // Set States
    setShowRoomActionDialog(false);
  };

  const onRoomActionDialogConfirmed = () => {
    switch (roomActionDialogType) {
      case "CreateRoom":
        createRoom();
        break;
      case "EditRoom":
        editRoom();
        break;
      default:
        break;
    }

    // Set States
    setShowRoomActionDialog(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "roomName":
        setRoomName(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!roomName) {
      addToErrorFields("roomName", "請先填寫 房間名稱");
      isError = true;
    }

    if (isError) {
      return;
    }

    displayRoomActionDialog(roomId ? "EditRoom" : "CreateRoom");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayRoomActionDialog = (roomActionType) => {
    // Set States
    setRoomActionDialogType(roomActionType);

    switch (roomActionType) {
      case "CreateRoom":
        setRoomActionDialogText("確認要新增 房間 嗎？");
        break;
      case "EditRoom":
        setRoomActionDialogText("確認要編輯 房間 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowRoomActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createRoom = async () => {
    const results = await createRoomFetch(token, shopId, roomName);

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 房間 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  const editRoom = async () => {
    const results = await editRoomFetch(token, roomId, roomName);

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 房間 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions = Queries
  const getRoomDetailsById = async () => {
    const results = await getRoomDetailsByIdFetch(token, roomId);

    if (results.roomDetails) {
      const { room_name } = results.roomDetails;

      // Set States
      setRoomName(room_name);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (roomId) {
      getRoomDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [roomId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onRoomActionDialogCanceled}
        onDialogConfirmed={onRoomActionDialogConfirmed}
        // States
        dialogText={roomActionDialogText}
        showDialog={showRoomActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {roomId ? "編輯房間" : "新增房間"}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("roomName")}
              fullWidth
              helperText={getErrorFieldMessage("roomName")}
              label="房間名稱"
              margin="dense"
              name="roomName"
              onChange={(event) =>
                onInputFieldChange("roomName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              required
              value={roomName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <SpacingBox
              // Render
              height={stylesConfig.spacingBoxMarginBottomSmall}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              確定
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default RoomActionModal;
