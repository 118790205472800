// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Fetches
import {
  createMedicalRecordFetch,
  editMedicalRecordFetch,
  getMedicalRecordDetailsByIdFetch,
} from "../../../fetches/medicalRecordFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PatientMedicalRecordActionModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    medicalRecordId,
  } = props;

  // Redux Store
  const patientId = useSelector((state) => state.patientProfilePage.patientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    patientMedicalRecordActionDialogText,
    setPatientMedicalRecordActionDialogText,
  ] = useState("");
  const [
    patientMedicalRecordActionDialogType,
    setPatientMedicalRecordActionDialogType,
  ] = useState(null);
  const [
    showPatientMedicalRecordActionDialog,
    setShowPatientMedicalRecordActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [medicalRecordName, setMedicalRecordName] = useState("");

  // Events
  // Events - Dialogs
  const onPatientMedicalRecordActionDialogCanceled = () => {
    // Set States
    setShowPatientMedicalRecordActionDialog(false);
  };

  const onPatientMedicalRecordActionDialogConfirmed = () => {
    switch (patientMedicalRecordActionDialogType) {
      case "CreatePatientMedicalRecord":
        createMedicalRecord();
        break;
      case "EditPatientMedicalRecord":
        editMedicalRecord();
        break;
      default:
        break;
    }

    // Set States
    setShowPatientMedicalRecordActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "medicalRecordName":
        setMedicalRecordName(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!medicalRecordName) {
      addToErrorFields("medicalRecordName", "請先填寫 病歷名稱");
      isError = true;
    }

    if (isError) {
      return;
    }

    displayPatientMedicalRecordActionDialog(
      medicalRecordId
        ? "EditPatientMedicalRecord"
        : "CreatePatientMedicalRecord"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayPatientMedicalRecordActionDialog = (
    patientMedicalRecordActionType
  ) => {
    // Set States
    setPatientMedicalRecordActionDialogType(patientMedicalRecordActionType);

    switch (patientMedicalRecordActionType) {
      case "CreatePatientMedicalRecord":
        setPatientMedicalRecordActionDialogText("確認要新增 病歷 嗎？");
        break;
      case "EditPatientMedicalRecord":
        setPatientMedicalRecordActionDialogText("確認要編輯 病歷 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientMedicalRecordActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createMedicalRecord = async () => {
    const results = await createMedicalRecordFetch(
      token,
      patientId,
      medicalRecordName
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 病歷 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  const editMedicalRecord = async () => {
    const results = await editMedicalRecordFetch(
      token,
      medicalRecordId,
      medicalRecordName
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 病歷 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions = Queries
  const getMedicalRecordDetailsById = async () => {
    const results = await getMedicalRecordDetailsByIdFetch(
      token,
      medicalRecordId
    );

    if (results.medicalRecordDetails) {
      const { medical_record_name } = results.medicalRecordDetails;

      // Set States
      setMedicalRecordName(medical_record_name ? medical_record_name : "");
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (medicalRecordId) {
      getMedicalRecordDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [medicalRecordId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientMedicalRecordActionDialogCanceled}
        onDialogConfirmed={onPatientMedicalRecordActionDialogConfirmed}
        // States
        dialogText={patientMedicalRecordActionDialogText}
        showDialog={showPatientMedicalRecordActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {medicalRecordId ? "編輯病歷" : "新增病歷"}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("medicalRecordName")}
              fullWidth
              helperText={getErrorFieldMessage("medicalRecordName")}
              label="病歷名稱"
              margin="dense"
              name="medicalRecordName"
              onChange={(event) =>
                onInputFieldChange("medicalRecordName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={medicalRecordName}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              確定
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default PatientMedicalRecordActionModal;
