// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function ShopToTherapyBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    editedShopToTherapies,
    item,
    selectedShopToTherapies,
    shopId,
    // Events
    onItemRightClicked,
    onMouseDown,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // States
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={
        selectedShopToTherapies.some(
          (selectedShopToTherapyItem) =>
            selectedShopToTherapyItem.shopId === shopId &&
            selectedShopToTherapyItem.therapyId === item.therapy_id
        )
          ? classes.selectedCellBoxLarge
          : isHovered
          ? classes.hoveredCellBoxLarge
          : classes.cellBoxLarge
      }
      onContextMenu={(event) => {
        event.preventDefault();
        onItemRightClicked(shopId, item.therapy_id, event.currentTarget);
      }}
      onMouseDown={(event) => onMouseDown(shopId, item.therapy_id, event)}
      onMouseEnter={() => {
        onMouseEnterItem(shopId, item.therapy_id);
        // Set States
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        onMouseLeaveItem();
        // Set States
        setIsHovered(false);
      }}
    >
      <Typography
        align={"center"}
        color={
          selectedShopToTherapies.some(
            (selectedShopToTherapyItem) =>
              selectedShopToTherapyItem.shopId === shopId &&
              selectedShopToTherapyItem.therapyId === item.therapy_id
          )
            ? stylesConfig.whiteTextColor
            : editedShopToTherapies.some(
                (editedShopToTherapyItem) =>
                  editedShopToTherapyItem.shopId === shopId &&
                  editedShopToTherapyItem.therapyId === item.therapy_id
              )
            ? stylesConfig.blueTextColor
            : item.is_capable
            ? null
            : stylesConfig.redTextColor
        }
        variant="body1"
      >
        {item.is_capable ? "O" : "X"}
      </Typography>
    </div>
  );
}

export default ShopToTherapyBox;
