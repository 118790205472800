export const getPagePath = (titleType) => {
  let path;

  switch (titleType) {
    case "Bookings":
      path = "/bookings";
      break;
    case "CreateBooking":
      path = "/createBooking";
      break;
    case "CreatePatient":
      path = "/createPatient";
      break;
    case "Patients":
      path = "/patients";
      break;
    case "PatientProfile":
      path = "/patientProfile";
      break;
    case "Therapies":
      path = "/therapies";
      break;
    default:
      path = "/";
      break;
  }

  return path;
};
