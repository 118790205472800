// Components
// Menus
import ShopToTherapyActionMenu from "../../menus/therapiesPage/shopToTherapyActionMenu";
// Tables
import TherapyBox from "./therapyBox";
import ShopBox from "./shopBox";
import ShopToTherapyBox from "./shopToTherapyBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// React
import { useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  tableContainerStyles,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function ShopToTherapyTable(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    editedShopToTherapies,
    hoveredTherapyId,
    isSelectMode,
    selectedShopToTherapies,
    shopWithTherapies,
    therapies,
    // Set States
    setHoveredTherapyId,
    setIsSelectMode,
    setSelectedShopToTherapies,
    // Functions
    createOrEditShopToTherapies,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Hover
  const [hoveredShopId, setHoveredShopId] = useState(null);
  // Menu
  const [showShopToTherapyActionMenu, setShowShopToTherapyActionMenu] =
    useState(null);

  // Events
  // Events - Box
  const onItemRightClicked = (shopId, therapyId, currentTarget) => {
    // Set States
    if (selectedShopToTherapies.length <= 1) {
      setSelectedShopToTherapies([{ shopId, therapyId }]);
    }

    // Set States
    setShowShopToTherapyActionMenu(currentTarget);
  };

  const onMouseDown = (shopId, therapyId, event) => {
    if (event.button === 0) {
      // Set States
      setIsSelectMode(true);

      if (shopId && therapyId) {
        // Set States
        setSelectedShopToTherapies((currentState) =>
          currentState.some(
            (item) => item.shopId === shopId && item.therapyId === therapyId
          )
            ? currentState.filter(
                (item) => item.shopId !== shopId || item.therapyId !== therapyId
              )
            : [...currentState, { shopId, therapyId }]
        );
      }
    }
  };

  // Events - Hover
  const onMouseEnterItem = (shopId, therapyId) => {
    // Set States
    setHoveredShopId(shopId);
    setHoveredTherapyId(therapyId);

    if (isSelectMode) {
      // Set States
      setSelectedShopToTherapies((currentState) =>
        currentState.some(
          (item) => item.shopId === shopId && item.therapyId === therapyId
        )
          ? currentState.filter(
              (item) => item.shopId !== shopId || item.therapyId !== therapyId
            )
          : [...currentState, { shopId, therapyId }]
      );
    }
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredShopId(null);
    setHoveredTherapyId(null);
  };

  return (
    <TableContainer sx={tableContainerStyles}>
      {/* Menu */}
      <ShopToTherapyActionMenu
        // States
        showShopToTherapyActionMenu={showShopToTherapyActionMenu}
        // Set States
        setShowShopToTherapyActionMenu={setShowShopToTherapyActionMenu}
        // Functions
        createOrEditShopToTherapies={createOrEditShopToTherapies}
      />
      <Table stickyHeader>
        <TableHead>
          {therapies && (
            <TableRow>
              <TableCell className={classes.stickyColumnHeader}></TableCell>
              {therapies.map((therapyItem) => (
                <TableCell
                  align="center"
                  key={therapyItem.id}
                  sx={{
                    backgroundColor:
                      themeMode === "light"
                        ? stylesConfig.contentBackgroudColorLight
                        : stylesConfig.contentBackgroudColorDark,
                  }}
                >
                  <TherapyBox
                    // States
                    hoveredTherapyId={hoveredTherapyId}
                    item={therapyItem}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        {shopWithTherapies && (
          <TableBody>
            {shopWithTherapies.map((shopItem, index) => (
              <TableRow key={shopItem.id}>
                <TableCell className={classes.stickyColumn} scope="row">
                  <ShopBox
                    // States
                    hoveredShopId={hoveredShopId}
                    index={index}
                    item={shopItem}
                  />
                </TableCell>
                {shopItem.shopToTherapies.map((shopToTherapyItem) => (
                  <TableCell
                    align="center"
                    key={shopToTherapyItem.therapy_id}
                    scope="row"
                  >
                    <ShopToTherapyBox
                      // States
                      editedShopToTherapies={editedShopToTherapies}
                      item={shopToTherapyItem}
                      selectedShopToTherapies={selectedShopToTherapies}
                      shopId={shopItem.id}
                      // Events
                      onItemRightClicked={onItemRightClicked}
                      onMouseDown={onMouseDown}
                      onMouseEnterItem={onMouseEnterItem}
                      onMouseLeaveItem={onMouseLeaveItem}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default ShopToTherapyTable;
