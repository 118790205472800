// Actions
import {
  onTabItemChange,
  onTypeOfTherapiesAndRoomsChange,
} from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function TherapiesPageHeader() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.therapiesPage.tabItem);
  const typeOfTherapiesAndRooms = useSelector(
    (state) => state.therapiesPage.typeOfTherapiesAndRooms
  );

  // Events
  // Events - Select Box
  const onTypeOfTherapiesAndRoomsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfTherapiesAndRoomsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"Therapies"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  療程
                </Typography>
              }
              value={"Therapies"}
            />
            <Tab
              disableRipple
              key={"Rooms"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  房間
                </Typography>
              }
              value={"Rooms"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      <div>
        <FormControl variant="standard" sx={selectBoxContainerStyles}>
          <Select
            value={typeOfTherapiesAndRooms}
            onChange={(event) =>
              onTypeOfTherapiesAndRoomsSelectBoxValueChanged(event.target.value)
            }
          >
            <MenuItem value={"Current"}>{`顯示有效${
              tabItem === "Therapies" ? "療程" : "房間"
            }`}</MenuItem>
            <MenuItem value={"Deleted"}>{`顯示已刪除${
              tabItem === "Therapies" ? "療程" : "房間"
            }`}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}

export default TherapiesPageHeader;
