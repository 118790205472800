// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useHorizontalItemBoxStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxStyles";

function TherapyBox(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    hoveredRoomId,
    hoveredRoomCapableTherapyIdsArr,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isAvailable = hoveredRoomId
    ? hoveredRoomCapableTherapyIdsArr.includes(item.id)
    : true;
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id, item.num_of_time_slot)}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isAvailable
                ? isSelected
                  ? stylesConfig.whiteTextColor
                  : isHovered
                  ? stylesConfig.blueTextColor
                  : null
                : stylesConfig.redTextColor
            }
            variant="body1"
          >
            {item.therapy_name}
          </Typography>
          <Typography
            align={"left"}
            color={
              isAvailable
                ? isSelected
                  ? stylesConfig.whiteTextColor
                  : stylesConfig.greyTextColor
                : stylesConfig.redTextColor
            }
            variant="body2"
          >
            {`${item.duration} 分鐘`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default TherapyBox;
