export function onBrandChange(brandId) {
  return {
    type: "ON_BOOKINGS_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_BOOKINGS_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onDateChange(date) {
  return {
    type: "ON_BOOKINGS_PAGE_DATE_CHANGE",
    date,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_BOOKINGS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onHandledByChange(handledById, handledByDisplayText) {
  return {
    type: "ON_BOOKINGS_PAGE_HANDLED_BY_CHANGE",
    handledById,
    handledByDisplayText,
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_BOOKINGS_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_BOOKINGS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
