// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import ContentContainer from "../components/boxes/bookingsPage/contentContainer";
import ShopBoxesContainer from "../components/boxes/bookingsPage/shopBoxesContainer";
// Headers
import BookingsPageHeader from "../components/headers/bookingsPage/bookingsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation } from "react-router-dom";

function BookingsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const location = useLocation();

  // Params
  const bookingIdParam = location.state ? location.state.bookingId : null;
  const numOfTimeSlotParam = location.state
    ? location.state.numOfTimeSlot
    : null;
  const therapyIdParam = location.state ? location.state.therapyId : null;

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Bookings"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <BookingsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <ShopBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <ContentContainer
            // States
            bookingIdParam={bookingIdParam}
            numOfTimeSlotParam={numOfTimeSlotParam}
            therapyIdParam={therapyIdParam}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default BookingsPage;
