// Actions
import { onRoomChange } from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import RoomSuggestionBox from "./roomSuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getRoomSuggestionsFetch } from "../../../fetches/roomFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function RoomSuggestionBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    bookingIdParam,
    // Functions
    displayBookingActionDialog,
    displaySearchPatientModal,
  } = props;

  // Redux Store
  const roomId = useSelector((state) => state.createBookingPage.roomId);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const timeSlotId = useSelector((state) => state.createBookingPage.timeSlotId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [rooms, setRooms] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = roomId;
  const itemsArr = rooms;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onRoomChange(itemId));

    if (bookingIdParam) {
      displayBookingActionDialog("EditBooking");
    } else {
      displaySearchPatientModal();
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getRoomSuggestions = async () => {
    const results = await getRoomSuggestionsFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      shopId,
      therapyId,
      timeSlotId
    );

    // Set States
    setRooms(results.rooms ? results.rooms : null);

    if (
      results.rooms &&
      roomId &&
      results.rooms.findIndex((item) => item.id === roomId) !== -1
    ) {
      return;
    }

    // Update Redux Store
    dispatch(onRoomChange(null));
  };

  // Life Cycle
  useEffect(() => {
    if (shopId && therapyId && timeSlotId) {
      getRoomSuggestions();
    } else {
      // Update Redux Store
      dispatch(onRoomChange(null));
    }
  }, [shopId, therapyId, timeSlotId]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            房間
          </Typography>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <RoomSuggestionBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有房間可進行此療程
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RoomSuggestionBoxesContainer;
