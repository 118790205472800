// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Boxes
import TherapyBoxesContainer from "./therapyBoxesContainer";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";
// Tables
import ShopToTherapyTableContainer from "../../tables/therapiessPage/shopToTherapyTableContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { createOrEditShopToTherapiesFetch } from "../../../fetches/shopToTherapyFetches";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function TherapyContentContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Hover
  const [hoveredTherapyId, setHoveredTherapyId] = useState(null);

  // Select Mode
  const [editedShopToTherapies, setEditedShopToTherapies] = useState([]);
  const [selectedShopToTherapies, setSelectedShopToTherapies] = useState([]);

  // Functions
  // Functions - Mutations
  const createOrEditShopToTherapies = async (isCapable) => {
    const results = await createOrEditShopToTherapiesFetch(
      token,
      selectedShopToTherapies,
      isCapable
    );

    if (results.success) {
      // Set States
      setSelectedShopToTherapies([]);
      setEditedShopToTherapies((currentState) => [
        ...currentState,
        ...selectedShopToTherapies,
      ]);

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  return (
    <>
      <TherapyBoxesContainer
        // States
        hoveredTherapyId={hoveredTherapyId}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <ShopToTherapyTableContainer
        // States
        editedShopToTherapies={editedShopToTherapies}
        hoveredTherapyId={hoveredTherapyId}
        selectedShopToTherapies={selectedShopToTherapies}
        // Set States
        setEditedShopToTherapies={setEditedShopToTherapies}
        setHoveredTherapyId={setHoveredTherapyId}
        setSelectedShopToTherapies={setSelectedShopToTherapies}
        // Functions
        createOrEditShopToTherapies={createOrEditShopToTherapies}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
    </>
  );
}

export default TherapyContentContainer;
