// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function TherapyBox(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredTherapyId,
    item,
  } = props;

  return (
    <div className={classes.fieldBoxWithPointer}>
      <Typography
        align={"center"}
        className={
          hoveredTherapyId === item.id
            ? classes.selectedCellBoxLarge
            : classes.cellBoxLargeWithoutPointer
        }
        color={
          hoveredTherapyId === item.id ? stylesConfig.whiteTextColor : null
        }
        variant="body1"
      >
        {item.therapy_name}
      </Typography>
    </div>
  );
}

export default TherapyBox;
