// Configs
import apisConfig from "../configs/apisConfig";

export const cancelBookingFetch = async (token, bookingId) => {
  try {
    const queryRoute = `/booking/cancelBooking/${bookingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createBookingFetch = async (
  token,
  roomId,
  patientId,
  therapyId,
  date,
  startTimeSlotId
) => {
  try {
    const queryRoute = "/booking/createBooking";

    const reqBody = {
      roomId,
      patientId,
      therapyId,
      date,
      startTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editBookingFetch = async (
  token,
  bookingId,
  roomId,
  therapyId,
  date,
  startTimeSlotId
) => {
  try {
    const queryRoute = `/booking/editBooking/${bookingId}`;

    const reqBody = {
      roomId,
      therapyId,
      date,
      startTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editBookingStatusTypeAndHandledByFetch = async (
  token,
  bookingId,
  bookingStatusTypeId,
  handledBy,
  endTimeSlotId
) => {
  try {
    const queryRoute = `/booking/editBookingStatusTypeAndHandledBy/${bookingId}`;

    const reqBody = {
      bookingStatusTypeId,
      handledBy,
      endTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllBookingsByPatientIdWithPaginationFetch = async (
  token,
  patientId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/booking/getAllBookingsByPatientIdWithPagination/${patientId}`;

    const reqBody = {
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllBookingsWithRoomsByShopIdAndDateFetch = async (
  token,
  shopId,
  date,
  handledById
) => {
  try {
    const queryRoute = `/booking/getAllBookingsWithRoomsByShopIdAndDate/${shopId}`;

    const reqBody = {
      date,
      handledById,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getBookingDetailsByIdFetch = async (token, bookingId) => {
  try {
    const queryRoute = `/booking/getBookingDetailsById/${bookingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const validateBookingFetch = async (
  token,
  bookingId,
  roomId,
  therapyId,
  date,
  startTimeSlotId
) => {
  try {
    const queryRoute = "/booking/validateBooking";

    const reqBody = {
      bookingId,
      roomId,
      therapyId,
      date,
      startTimeSlotId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
