// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/patientProfilePage/patientProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import PatientInfoFormBasicInfoContent from "./patientInfoFormBasicInfoContent";
import PatientInfoFormContactAndAddressContent from "./patientInfoFormContactAndAddressContent";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  editPatientFetch,
  getPatientDetailsByIdFetch,
} from "../../../fetches/patientFetches";
import { getPatientApplicationFormOptionsFetch } from "../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function PatientInfoFormContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const patientId = useSelector((state) => state.patientProfilePage.patientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateBirth, setDateBirth] = useState(null);
  // Dialog
  const [patientInfoActionDialogText, setPatientInfoActionDialogText] =
    useState("");
  const [patientInfoActionDialogType, setPatientInfoActionDialogType] =
    useState(null);
  const [showPatientInfoActionDialog, setShowPatientInfoActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [hkid, setHkid] = useState("");
  const [fullNameCh, setFullNameCh] = useState("");
  const [fullNameEn, setFullNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  // Events
  // Events - Dialogs
  const onPatientInfoActionDialogCanceled = () => {
    // Set States
    setShowPatientInfoActionDialog(false);
  };

  const onPatientInfoActionDialogConfirmed = () => {
    // Set States
    setShowPatientInfoActionDialog(false);

    switch (patientInfoActionDialogType) {
      // 修改
      case "EditPatient":
        editPatient();
        break;
      default:
        break;
    }
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "address":
        setAddress(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "fullNameCh":
        setFullNameCh(value);
        break;
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked("EditPatient");
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!dateBirth) {
      addToErrorFields("dateBirth", "請先填寫 出生日期");
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", "請先填寫 性別");
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", "請先填寫 身分證編號");
      isError = true;
    }

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", "請先填寫 英文全名");
      isError = true;
    }

    if (!phoneNum) {
      addToErrorFields("phoneNum", "請先填寫 電話號碼");
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", "請先填寫 區域");
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", "請先填寫 地區");
      isError = true;
    }

    if (!address) {
      addToErrorFields("address", "請先填寫 地址");
      isError = true;
    }

    if (isError) {
      return;
    }

    displayPatientInfoActionDialog("EditPatient");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayPatientInfoActionDialog = (patientInfoActionType) => {
    // Set States
    setPatientInfoActionDialogType(patientInfoActionType);

    switch (patientInfoActionType) {
      // 修改
      case "EditPatient":
        setPatientInfoActionDialogText("確認要編輯 病人資料 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientInfoActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editPatient = async () => {
    if (patientId) {
      const results = await editPatientFetch(
        token,
        patientId,
        hkid,
        genderTypeField ? genderTypeField.id : null,
        fullNameCh,
        fullNameEn,
        dateBirth,
        email,
        phoneNum,
        districtField ? districtField.id : null,
        address
      );

      if (results.success) {
        showFormAlert("success", "成功提交");

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", "此 身份證 已被登記");
      } else {
        showFormAlert("error", "未能提交");
      }
    }
  };

  // Functions - Queries
  const getPatientDetailsById = async () => {
    const results = await getPatientDetailsByIdFetch(token, patientId);

    if (results.patientDetails) {
      const {
        hkid,
        gender_type_id,
        full_name_ch,
        full_name_en,
        date_birth,
        email,
        phone_num,
        address,
        district_id,
        area_id,
      } = results.patientDetails;

      // Set States
      setHkid(hkid ? hkid : "");
      setGenderTypeField(
        gender_type_id
          ? genderTypeOptions.find((item) => item.id === gender_type_id)
          : null
      );
      setFullNameCh(full_name_ch ? full_name_ch : "");
      setFullNameEn(full_name_en ? full_name_en : "");
      setDateBirth(date_birth ? date_birth : null);
      setEmail(email ? email : "");
      setPhoneNum(phone_num ? phone_num : "");
      setAddress(address ? address : "");
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
    } else {
      // Set States
      setHkid("");
      setGenderTypeField(null);
      setFullNameCh("");
      setFullNameEn("");
      setDateBirth(null);
      setEmail("");
      setPhoneNum("");
      setAddress("");
      setDistrictField(null);
      setAreaField(null);
    }

    clearErrorFields();
  };

  const getPatientApplicationFormOptions = async () => {
    const results = await getPatientApplicationFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
  };

  // Life Cycle
  useEffect(() => {
    getPatientApplicationFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (patientId) {
        getPatientDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, patientId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientInfoActionDialogCanceled}
        onDialogConfirmed={onPatientInfoActionDialogConfirmed}
        // States
        dialogText={patientInfoActionDialogText}
        showDialog={showPatientInfoActionDialog}
      />
      <PatientInfoFormBasicInfoContent
        // States
        dateBirth={dateBirth}
        genderTypeField={genderTypeField}
        genderTypeOptions={genderTypeOptions}
        hkid={hkid}
        fullNameCh={fullNameCh}
        fullNameEn={fullNameEn}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <PatientInfoFormContactAndAddressContent
        // States
        address={address}
        areaField={areaField}
        areaOptions={areaOptions}
        districtField={districtField}
        districtOptions={districtOptions}
        email={email}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        phoneNum={phoneNum}
        shouldShowFormAlert={shouldShowFormAlert}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default PatientInfoFormContainer;
