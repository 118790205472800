// Components
// Boxes
import TherapyBox from "./therapyBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getAllTherapiesByShopIdFetch } from "../../../fetches/therapyFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function TherapyBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();

  // Props
  const {
    // States
    hoveredRoomId,
    hoveredRoomCapableTherapyIdsArr,
    selectedBookingId,
    selectedTherapyId,
    // Set States
    setNumOfAffectedTimeSlots,
    setSelectedTherapyId,
    // Functions
    clearSelectedTherapy,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.bookingsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [therapies, setTherapies] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = selectedTherapyId;
  const itemsArr = therapies;

  // Events
  // Events - Box
  const onItemClicked = (itemId, numOfTimeSlots) => {
    if (currentItemId && currentItemId === itemId) {
      if (selectedBookingId) {
        return;
      }

      clearSelectedTherapy();
      return;
    }

    // Set States
    setNumOfAffectedTimeSlots(numOfTimeSlots);
    setSelectedTherapyId(itemId);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getAllTherapiesByShopId = async () => {
    const results = await getAllTherapiesByShopIdFetch(
      token,
      shopId,
      "Current"
    );

    // Set States
    setTherapies(results.therapies ? results.therapies : null);
  };

  // Life Cycle
  useEffect(() => {
    if (shopId) {
      getAllTherapiesByShopId();
    }
  }, [shopId]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            療程
          </Typography>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <TherapyBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              hoveredRoomId={hoveredRoomId}
              hoveredRoomCapableTherapyIdsArr={hoveredRoomCapableTherapyIdsArr}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有療程資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TherapyBoxesContainer;
