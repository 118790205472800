// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RoomBox(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredRoomId,
    item,
  } = props;

  return (
    <div className={classes.fieldBoxWithPointer}>
      <Typography
        align={"center"}
        className={
          hoveredRoomId === item.id
            ? classes.selectedCellBoxLarge
            : classes.cellBoxLargeWithoutPointer
        }
        color={hoveredRoomId === item.id ? stylesConfig.whiteTextColor : null}
        variant="body1"
      >
        {item.room_name}
      </Typography>
    </div>
  );
}

export default RoomBox;
