const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  areaId: null,
  areaItem: null,
  brandId: null,
  date: null,
  dayId: null,
  districtId: null,
  districtItem: null,
  month: currentMonth.toString(),
  roomId: null,
  shopId: null,
  tabItem: "CreateBooking",
  therapyId: null,
  therapyItem: null,
  timeSlotId: null,
};

export const createBookingPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CREATE_BOOKING_PAGE_AREA_CHANGE":
      const { areaId } = action;
      return {
        ...state,
        areaId,
      };
    case "ON_CREATE_BOOKING_PAGE_AREA_ITEM_CHANGE":
      const { areaItem } = action;
      return {
        ...state,
        areaItem,
      };
    case "ON_CREATE_BOOKING_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_CREATE_BOOKING_PAGE_DATE_CHANGE":
      const { date } = action;
      return {
        ...state,
        date,
      };
    case "ON_CREATE_BOOKING_PAGE_DAY_CHANGE":
      const { dayId } = action;
      return {
        ...state,
        dayId,
      };
    case "ON_CREATE_BOOKING_PAGE_DISTRICT_CHANGE":
      const { districtId } = action;
      return {
        ...state,
        districtId,
      };
    case "ON_CREATE_BOOKING_PAGE_DISTRICT_ITEM_CHANGE":
      const { districtItem } = action;
      return {
        ...state,
        districtItem,
      };
    case "ON_CREATE_BOOKING_PAGE_MONTH_CHANGE":
      const { month } = action;
      return {
        ...state,
        month,
      };
    case "ON_CREATE_BOOKING_PAGE_ROOM_CHANGE":
      const { roomId } = action;
      return {
        ...state,
        roomId,
      };
    case "ON_CREATE_BOOKING_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_CREATE_BOOKING_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_CREATE_BOOKING_PAGE_THERAPY_CHANGE":
      const { therapyId } = action;
      return {
        ...state,
        therapyId,
      };
    case "ON_CREATE_BOOKING_PAGE_THERAPY_ITEM_CHANGE":
      const { therapyItem } = action;
      return {
        ...state,
        therapyItem,
      };
    case "ON_CREATE_BOOKING_PAGE_TIME_SLOT_CHANGE":
      const { timeSlotId } = action;
      return {
        ...state,
        timeSlotId,
      };
    default:
      return state;
  }
};
