// Actions
import {
  onActiveStepDecrease,
  onActiveStepIncrease,
  onActiveStepReset,
} from "../../../redux/pages/createPatientPage/createPatientPageActions";

// Components
// Boxes
import MedicalRecordBoxesContainer from "../../boxes/createPatientPage/medicalRecordBoxesContainer";
// Forms
import CreatePatientFormBasicInfoContent from "./createPatientFormBasicInfoContent";
import CreatePatientFormContactAndAddressContent from "./createPatientFormContactAndAddressContent";

// Fetches
import { createPatientFetch } from "../../../fetches/patientFetches";
import { getPatientApplicationFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Helper Functions
import {
  createInitialMedicalRecordItem,
  createMedicalRecordItem,
} from "../../../helperFunctions/createMedicalRecordItem";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function CreatePatientFormContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Redux Store
  const activeStep = useSelector((state) => state.createPatientPage.activeStep);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [dateBirth, setDateBirth] = useState(null);
  // Dynamic Fields
  const [medicalRecords, setMedicalRecords] = useState([
    createInitialMedicalRecordItem(),
  ]);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // Options Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [hkid, setHkid] = useState("");
  const [fullNameCh, setFullNameCh] = useState("");
  const [fullNameEn, setFullNameEn] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  // Events
  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }
          }

          return tempState;
        });
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "address":
        setAddress(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          districtOptions.find((item) => item.area_id === value.id)
        );

        removeErrorField("districtField");

        break;
      case "dateBirth":
        setDateBirth(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "hkid":
        setHkid(value);
        break;
      case "fullNameCh":
        setFullNameCh(value);
        break;
      case "fullNameEn":
        setFullNameEn(value);
        break;
      case "phoneNum":
        setPhoneNum(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      switch (activeStep) {
        case 0:
          onCreatePatientFormBasicInfoContentSubmitBtnClicked();
          break;
        case 1:
          onCreatePatientFormContactAddressContentSubmitBtnClicked();
          break;
        case 2:
          onCreatePatientFormMedicalRecordContentSubmitBtnClicked();
          break;
        default:
          break;
      }
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) => [
          ...currentState,
          createMedicalRecordItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "medicalRecord":
        setMedicalRecords((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  const onCreatePatientFormBasicInfoContentSubmitBtnClicked = () => {
    let isError = false;

    if (!dateBirth) {
      addToErrorFields("dateBirth", "請先填寫 出生日期");
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", "請先填寫 性別");
      isError = true;
    }

    if (!hkid) {
      addToErrorFields("hkid", "請先填寫 身分證編號");
      isError = true;
    }

    if (!fullNameEn) {
      addToErrorFields("fullNameEn", "請先填寫 英文全名");
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onCreatePatientFormContactAddressContentSubmitBtnClicked = () => {
    let isError = false;

    if (!phoneNum) {
      addToErrorFields("phoneNum", "請先填寫 電話號碼");
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", "請先填寫 區域");
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", "請先填寫 地區");
      isError = true;
    }

    if (!address) {
      addToErrorFields("address", "請先填寫 地址");
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onCreatePatientFormMedicalRecordContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of medicalRecords) {
      if (!item.medicalRecordName) {
        addToDynamicErrorFields(
          "medicalRecord",
          item.id,
          "medicalRecordName",
          "請先填寫 病歷名稱"
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    createPatient();
  };

  const onStepBackBtnClicked = () => {
    if (activeStep !== 0) {
      // Update Redux Store
      dispatch(onActiveStepDecrease());
    }
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createPatient = async () => {
    const results = await createPatientFetch(
      hkid,
      genderTypeField ? genderTypeField.id : null,
      fullNameCh,
      fullNameEn,
      dateBirth,
      email,
      phoneNum,
      districtField ? districtField.id : null,
      address,
      medicalRecords
    );

    if (results.success) {
      // Navigate
      navigate("/patients");
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 身份證 已被登記");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions - Queries
  const getPatientApplicationFormOptions = async () => {
    const results = await getPatientApplicationFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
  };

  // Life Cycle
  useEffect(() => {
    getPatientApplicationFormOptions();

    // Update Redux Store
    dispatch(onActiveStepReset());
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    // Set States
    setShouldShowFormAlert(false);
  }, [activeStep]);

  return (
    <>
      {activeStep === 0 && (
        <CreatePatientFormBasicInfoContent
          // States
          dateBirth={dateBirth}
          genderTypeField={genderTypeField}
          genderTypeOptions={genderTypeOptions}
          hkid={hkid}
          fullNameCh={fullNameCh}
          fullNameEn={fullNameEn}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreatePatientFormBasicInfoContentSubmitBtnClicked
          }
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 1 && (
        <CreatePatientFormContactAndAddressContent
          // States
          address={address}
          areaField={areaField}
          areaOptions={areaOptions}
          districtField={districtField}
          districtOptions={districtOptions}
          email={email}
          phoneNum={phoneNum}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreatePatientFormContactAddressContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 2 && (
        <MedicalRecordBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreatePatientFormMedicalRecordContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // States
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          medicalRecords={medicalRecords}
          shouldShowFormAlert={shouldShowFormAlert}
        />
      )}
    </>
  );
}

export default CreatePatientFormContainer;
