// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useHorizontalItemBoxStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxStyles";

function RoomBox(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    hoveredRoomId,
    index,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isRoomHovered = hoveredRoomId === item.id;

  return (
    <div
      className={
        isRoomHovered
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }}
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isRoomHovered
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.blueTextColor
                : null
            }
            variant="body1"
          >
            {item.room_name}
          </Typography>
          <Typography
            align={"left"}
            color={
              isRoomHovered
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {`房間 ${index + 1}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default RoomBox;
