// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function ShopBox(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredShopId,
    index,
    item,
  } = props;

  return (
    <div>
      <div
        className={
          hoveredShopId === item.id
            ? classes.selectedHeadCellBox
            : classes.headCellBoxWithoutPointer
        }
      >
        <Typography
          align={"left"}
          color={hoveredShopId === item.id ? stylesConfig.whiteTextColor : null}
          variant="body1"
        >
          {item.shop_code}
        </Typography>
      </div>
      <div className={classes.headCellBox}>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {item.shop_name_ch ? item.shop_name_ch : `店鋪 ${index + 1}`}
        </Typography>
      </div>
    </div>
  );
}

export default ShopBox;
