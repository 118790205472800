// Actions
import { onHandledByChange } from "../../../redux/pages/bookingsPage/bookingsPageActions";

// Dummy Datum
import { handledByDummyData } from "../../../dummyDatum/handledByDummyData";

// Fetches
import { getAllStaffsByShopIdAndDateFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function HandledByActionModal(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const date = useSelector((state) => state.bookingsPage.date);
  const handledById = useSelector((state) => state.bookingsPage.handledById);
  const shopId = useSelector((state) => state.bookingsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Option Fields
  const [handledByField, setHandledByField] = useState(null);
  // Options
  const [handledByOptions, setHandledByOptions] = useState([]);

  // Handle States
  const dateVar = new Date(date);

  // Events

  // Events - Fields
  const onInputFieldChange = (value) => {
    // Set States
    setHandledByField(value);

    // Update Redux Store
    dispatch(
      onHandledByChange(
        value.id,
        value.staff_code
          ? `${value.staff_code} - ${value.full_name_en}`
          : value.full_name_en
      )
    );

    onModalClosed();
  };

  // Functions
  // Functions = Queries
  const getAllStaffsByShopIdAndDate = async () => {
    const results = await getAllStaffsByShopIdAndDateFetch(
      token,
      shopId,
      dateVar,
      []
    );

    const handledByItem = results.staffs
      ? [...handledByDummyData, ...results.staffs].find(
          (item) => item.id === handledById
        )
      : null;
    const handledByFieldValue = handledByItem
      ? handledByItem
      : handledByDummyData[0];

    // Set States
    setHandledByOptions(
      results.staffs ? [...handledByDummyData, ...results.staffs] : []
    );
    setHandledByField(handledByItem ? handledByItem : handledByDummyData[0]);

    // Update Redux Store
    dispatch(
      onHandledByChange(
        handledByFieldValue.id,
        handledByFieldValue.staff_code
          ? `${handledByFieldValue.staff_code} - ${handledByFieldValue.full_name_en}`
          : handledByFieldValue.full_name_en
      )
    );
  };

  // Life Cycle
  useEffect(() => {
    getAllStaffsByShopIdAndDate();
  }, [shopId, date]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            篩選預約
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {handledByOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option.staff_code
                    ? `${option.staff_code} - ${option.full_name_en}`
                    : option.full_name_en
                }
                onChange={(event, value) => onInputFieldChange(value)}
                options={handledByOptions}
                renderInput={(params) => (
                  <TextField {...params} label="負責員工" variant="outlined" />
                )}
                value={handledByField}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default HandledByActionModal;
