// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RoomToTherapyBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    editedRoomToTherapies,
    item,
    roomId,
    selectedRoomToTherapies,
    // Events
    onItemRightClicked,
    onMouseDown,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // States
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={
        selectedRoomToTherapies.some(
          (selectedRoomToTherapyItem) =>
            selectedRoomToTherapyItem.roomId === roomId &&
            selectedRoomToTherapyItem.therapyId === item.therapy_id
        )
          ? classes.selectedCellBoxLarge
          : isHovered
          ? classes.hoveredCellBoxLarge
          : classes.cellBoxLarge
      }
      onContextMenu={(event) => {
        event.preventDefault();
        onItemRightClicked(roomId, item.therapy_id, event.currentTarget);
      }}
      onMouseDown={(event) => onMouseDown(roomId, item.therapy_id, event)}
      onMouseEnter={() => {
        onMouseEnterItem(roomId, item.therapy_id);
        // Set States
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        onMouseLeaveItem();
        // Set States
        setIsHovered(false);
      }}
    >
      <Typography
        align={"center"}
        color={
          selectedRoomToTherapies.some(
            (selectedRoomToTherapyItem) =>
              selectedRoomToTherapyItem.roomId === roomId &&
              selectedRoomToTherapyItem.therapyId === item.therapy_id
          )
            ? stylesConfig.whiteTextColor
            : editedRoomToTherapies.some(
                (editedRoomToTherapyItem) =>
                  editedRoomToTherapyItem.roomId === roomId &&
                  editedRoomToTherapyItem.therapyId === item.therapy_id
              )
            ? stylesConfig.blueTextColor
            : item.is_capable
            ? null
            : stylesConfig.redTextColor
        }
        variant="body1"
      >
        {item.is_capable ? "O" : "X"}
      </Typography>
    </div>
  );
}

export default RoomToTherapyBox;
