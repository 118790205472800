// Actions
import {
  onTabItemChange,
  onTypeOfPatientsChange,
} from "../../../redux/pages/patientsPage/patientsPageActions";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function PatientsPageHeader() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.patientsPage.tabItem);
  const typeOfPatients = useSelector(
    (state) => state.patientsPage.typeOfPatients
  );

  // Events
  // Events - Select Box
  const onTypeOfPatientsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfPatientsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Patients"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                病人
              </Typography>
            }
            value={"Patients"}
          />
        </Tabs>
      </div>
      {/* Select Boxes */}
      <div>
        <FormControl variant="standard" sx={selectBoxContainerStyles}>
          <Select
            value={typeOfPatients}
            onChange={(event) =>
              onTypeOfPatientsSelectBoxValueChanged(event.target.value)
            }
          >
            <MenuItem value={"Current"}>顯示有效病人</MenuItem>
            <MenuItem value={"Deleted"}>顯示已刪除病人</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}

export default PatientsPageHeader;
