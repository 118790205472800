const initialState = {
  activeStep: 0,
};

export const createPatientPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CREATE_PATIENT_PAGE_ACTIVE_STEP_DECREASE":
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case "ON_CREATE_PATIENT_PAGE_ACTIVE_STEP_INCREASE":
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case "ON_CREATE_PATIENT_PAGE_ACTIVE_STEP_RESET":
      return {
        ...state,
        activeStep: 0,
      };
    default:
      return state;
  }
};
