// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import BookingConditionBoxesContainer from "../components/boxes/createBookingPage/bookingConditionBoxesContainer";
import ContentContainer from "../components/boxes/createBookingPage/contentContainer";
// Headers
import CreateBookingPageHeader from "../components/headers/createBookingPage/createBookingPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation } from "react-router-dom";

function CreateBookingPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const location = useLocation();

  // Params
  const areaIdParam = location.state ? location.state.areaId : null;
  const bookingIdParam = location.state ? location.state.bookingId : null;
  const districtIdParam = location.state ? location.state.districtId : null;
  const therapyIdParam = location.state ? location.state.therapyId : null;

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("CreateBooking"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <CreateBookingPageHeader
        // States
        bookingIdParam={bookingIdParam}
      />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <BookingConditionBoxesContainer
            // States
            areaIdParam={areaIdParam}
            districtIdParam={districtIdParam}
            therapyIdParam={therapyIdParam}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <ContentContainer
            // States
            bookingIdParam={bookingIdParam}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default CreateBookingPage;
