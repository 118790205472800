// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function SuggestionBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    hoveredItemType,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered =
    hoveredItemId &&
    hoveredItemId === item.id &&
    hoveredItemType &&
    hoveredItemType === item.type_en;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id, item.type_en)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() =>
          onItemClicked(
            item.id,
            item.type_en,
            item.meta_data_category_id,
            item.meta_data_id,
            item.meta_data_type
          )
        }
      >
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            {item.main_code ? `${item.main_code} - ` : null}
            {item.main_name}
            {item.main_name_sub ? ` (${item.main_name_sub})` : null}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.sub_code ? `${item.sub_code} - ` : null}
            {item.sub_name}
          </Typography>
        </div>
        <div>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.type_ch}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default SuggestionBox;
