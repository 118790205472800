// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import PatientBoxesContainer from "../components/boxes/patientsPage/patientBoxesContainer";
import PatientSearchBoxesContainer from "../components/boxes/patientsPage/patientSearchBoxesContainer";
// Headers
import PatientsPageHeader from "../components/headers/patientsPage/patientsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function PatientsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Patients"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <PatientsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <PatientSearchBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <PatientBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PatientsPage;
