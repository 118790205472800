// Configs
import apisConfig from "../configs/apisConfig";

export const createRoomFetch = async (token, shopId, roomName) => {
  try {
    const queryRoute = "/room/createRoom";

    const reqBody = {
      shopId,
      roomName,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreRoomFetch = async (token, roomId, typeOfRooms) => {
  try {
    const queryRoute = `/room/deleteOrRestoreRoom/${roomId}`;

    const reqBody = {
      typeOfRooms,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editRoomFetch = async (token, roomId, roomName) => {
  try {
    const queryRoute = `/room/editRoom/${roomId}`;

    const reqBody = {
      roomName,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllRoomsByShopIdFetch = async (token, shopId, typeOfRooms) => {
  try {
    const queryRoute = `/room/getAllRoomsByShopId/${shopId}`;

    const reqBody = { typeOfRooms };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRoomDetailsByIdFetch = async (token, roomId) => {
  try {
    const queryRoute = `/room/getRoomDetailsById/${roomId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRoomSuggestionsFetch = async (
  token,
  bookingId,
  shopId,
  therapyId,
  timeSlotId
) => {
  try {
    const queryRoute = "/room/getRoomSuggestions";

    const reqBody = { bookingId, shopId, therapyId, timeSlotId };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
