// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/bookingsPage/bookingsPageActions";

// Components
// Boxes
import TherapyBoxesContainer from "./therapyBoxesContainer";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";
// Tables
import TimeSlotToRoomTableContainer from "../../tables/bookingsPage/timeSlotToRoomTableContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  cancelBookingFetch,
  createBookingFetch,
  editBookingFetch,
  validateBookingFetch,
} from "../../../fetches/bookingFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ContentContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    bookingIdParam,
    numOfTimeSlotParam,
    therapyIdParam,
  } = props;

  // Redux Store
  const date = useSelector((state) => state.bookingsPage.date);
  const token = useSelector((state) => state.staff.token);

  // States
  // Hover
  const [hoveredRoomId, setHoveredRoomId] = useState(null);
  const [hoveredRoomCapableTherapyIdsArr, setHoveredRoomCapableTherapyIdsArr] =
    useState([]);
  // Render
  const [isEditMode, setIsEditMode] = useState(false);
  // Select
  const [numOfAffectedTimeSlots, setNumOfAffectedTimeSlots] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [selectedBookingStatusTypeId, setSelectedBookingStatusTypeId] =
    useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [
    selectedRoomCapableTherapyIdsArr,
    setSelectedRoomCapableTherapyIdsArr,
  ] = useState([]);
  const [selectedTherapyId, setSelectedTherapyId] = useState(null);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);
  // Snackbar
  const [showBookingActionSnackbar, setShowBookingActionSnackbar] =
    useState(false);
  const [bookingActionSnackbarText, setBookingActionSnackbarText] =
    useState("");
  const [bookingActionSnackbarType, setBookingActionSnackbarType] =
    useState("success");

  // Events
  // Events - Snackbar
  const onBookingActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowBookingActionSnackbar(false);
  };

  // Functions
  // Fucntions - Normal
  const clearAll = () => {
    // Set States
    setIsEditMode(false);
    setNumOfAffectedTimeSlots(null);
    setSelectedBookingId(null);
    setSelectedRoomId(null);
    setSelectedRoomCapableTherapyIdsArr([]);
    setSelectedTherapyId(null);
    setSelectedTimeSlotId(null);
  };

  const clearSelectedTherapy = () => {
    // Set States
    setNumOfAffectedTimeSlots(null);
    setSelectedTherapyId(null);
  };

  const displayBookingActionSnackBar = (bookingActionMessage) => {
    // Set States
    setBookingActionSnackbarText(bookingActionMessage);
    setBookingActionSnackbarType("warning");

    setShowBookingActionSnackbar(true);
  };

  const setDefaultBooking = () => {
    if (bookingIdParam && numOfTimeSlotParam && therapyIdParam) {
      // Set States
      setSelectedBookingId(bookingIdParam);
      setNumOfAffectedTimeSlots(numOfTimeSlotParam);
      setSelectedTherapyId(therapyIdParam);

      setIsEditMode(true);
    }
  };

  // Functions - Mutations
  const cancelBooking = async () => {
    const results = await cancelBookingFetch(token, selectedBookingId);

    if (results.success) {
      clearAll();

      dispatch(onFormSubmitCountIncrease());
    }
  };

  const createBooking = async (patientId) => {
    const results = await createBookingFetch(
      token,
      selectedRoomId,
      patientId,
      selectedTherapyId,
      date,
      selectedTimeSlotId
    );

    if (results.success) {
      clearAll();

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  const editBooking = async (roomId, startTimeSlotId) => {
    const results = await editBookingFetch(
      token,
      selectedBookingId,
      roomId,
      selectedTherapyId,
      date,
      startTimeSlotId
    );

    if (results.success) {
      clearAll();

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  const validateBooking = async (roomId, startTimeSlotId, therapyId) => {
    const results = await validateBookingFetch(
      token,
      selectedBookingId,
      roomId,
      therapyId,
      date,
      startTimeSlotId
    );

    if (results.success) {
      return true;
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }

    return false;
  };

  // Life Cycle
  useEffect(() => {
    setDefaultBooking();
  }, []);

  return (
    <>
      <TherapyBoxesContainer
        // States
        hoveredRoomId={hoveredRoomId}
        hoveredRoomCapableTherapyIdsArr={hoveredRoomCapableTherapyIdsArr}
        selectedBookingId={selectedBookingId}
        selectedTherapyId={selectedTherapyId}
        // Set States
        setNumOfAffectedTimeSlots={setNumOfAffectedTimeSlots}
        setSelectedTherapyId={setSelectedTherapyId}
        // Functions
        clearSelectedTherapy={clearSelectedTherapy}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <TimeSlotToRoomTableContainer
        // States
        bookingActionSnackbarText={bookingActionSnackbarText}
        bookingActionSnackbarType={bookingActionSnackbarType}
        hoveredRoomId={hoveredRoomId}
        hoveredRoomCapableTherapyIdsArr={hoveredRoomCapableTherapyIdsArr}
        isEditMode={isEditMode}
        numOfAffectedTimeSlots={numOfAffectedTimeSlots}
        selectedBookingId={selectedBookingId}
        selectedBookingStatusTypeId={selectedBookingStatusTypeId}
        selectedRoomId={selectedRoomId}
        selectedRoomCapableTherapyIdsArr={selectedRoomCapableTherapyIdsArr}
        selectedTherapyId={selectedTherapyId}
        selectedTimeSlotId={selectedTimeSlotId}
        showBookingActionSnackbar={showBookingActionSnackbar}
        // Set States
        setHoveredRoomId={setHoveredRoomId}
        setHoveredRoomCapableTherapyIdsArr={setHoveredRoomCapableTherapyIdsArr}
        setIsEditMode={setIsEditMode}
        setNumOfAffectedTimeSlots={setNumOfAffectedTimeSlots}
        setSelectedBookingId={setSelectedBookingId}
        setSelectedBookingStatusTypeId={setSelectedBookingStatusTypeId}
        setSelectedRoomId={setSelectedRoomId}
        setSelectedRoomCapableTherapyIdsArr={
          setSelectedRoomCapableTherapyIdsArr
        }
        setSelectedTherapyId={setSelectedTherapyId}
        setSelectedTimeSlotId={setSelectedTimeSlotId}
        // Events
        onBookingActionSnackbarClosed={onBookingActionSnackbarClosed}
        // Functions
        cancelBooking={cancelBooking}
        clearAll={clearAll}
        clearSelectedTherapy={clearSelectedTherapy}
        createBooking={createBooking}
        editBooking={editBooking}
        validateBooking={validateBooking}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
    </>
  );
}

export default ContentContainer;
