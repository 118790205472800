// Actions
import {
  onBrandChange,
  onBrandItemChange,
  onShopChange,
} from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Boxes
import ShopBox from "./shopBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/brandFetches";
import { getAllShopsByBrandIdFetch } from "../../../fetches/shopFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ShopBoxesContainer() {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const brandId = useSelector((state) => state.therapiesPage.brandId);
  const brandItem = useSelector((state) => state.therapiesPage.brandItem);
  const shopId = useSelector((state) => state.therapiesPage.shopId);
  const tabItem = useSelector((state) => state.therapiesPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [shops, setShops] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = shopId;
  const itemsArr = shops;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onShopChange(itemId));
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "brand":
        dispatch(onBrandChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setBrands(results.brands ? results.brands : null);

    // Update Redux Store
    if (results.brands && !brandId) {
      dispatch(onBrandChange(results.brands[0].id));
    }
  };

  const getAllShops = async (shoulddUpdateCurrentShopId) => {
    const results = await getAllShopsByBrandIdFetch(token, brandId, "Current");

    // Set States
    setShops(results.shops ? results.shops : null);

    // Update Redux Store
    if (
      results.shops &&
      (shoulddUpdateCurrentShopId ||
        !results.shops.some((item) => item.id === shopId))
    ) {
      dispatch(onShopChange(results.shops[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (brands && brandId) {
      dispatch(onBrandItemChange(brands.find((item) => item.id === brandId)));
    }
  }, [brands, brandId]);

  useEffect(() => {
    getAllBrands();
  }, []);

  useEffect(() => {
    if (brandId) {
      getAllShops(shopId ? false : true);
    }
  }, [brandId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            品牌
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {brands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.brand_name_en_full}
            onChange={(event, value) => onInputFieldChange("brand", value)}
            options={brands}
            renderInput={(params) => (
              <TextField {...params} label="品牌" variant="outlined" />
            )}
            value={brandItem}
          />
        </div>
      )}
      {tabItem === "Rooms" && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                店鋪
              </Typography>
            </div>
          </div>
          {/* List */}
          {itemsArr ? (
            itemsArr.map((item) => (
              <ShopBox
                key={item.id}
                // States
                currentItemId={currentItemId}
                hoveredItemId={hoveredItemId}
                item={item}
                // Events
                onItemClicked={onItemClicked}
                onMouseEnterItem={onMouseEnterItem}
                onMouseLeaveItem={onMouseLeaveItem}
              />
            ))
          ) : (
            <div className={classes.emptyDataContainer}>
              <Typography align={"center"} color={stylesConfig.greyTextColor}>
                未有店鋪資料
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ShopBoxesContainer;
