// Actions
import {
  onDateChange,
  onDayChange,
  onMonthChange,
} from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import DaySuggestionBox from "./daySuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getDaySuggestionsFetch } from "../../../fetches/dayFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function DaySuggestionBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const { bookingIdParam } = props;

  // Redux Store
  const dayId = useSelector((state) => state.createBookingPage.dayId);
  const month = useSelector((state) => state.createBookingPage.month);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [days, setDays] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = dayId;
  const itemsArr = days;
  const monthVar = month ? new Date(month) : null;

  const isMonthVarEqualsCurrentMonth =
    monthVar.getFullYear() === currentMonth.getFullYear() &&
    monthVar.getMonth() === currentMonth.getMonth();

  // Events
  // Events - Box
  const onItemClicked = (itemId, itemDate) => {
    // Update Redux Store
    dispatch(onDateChange(itemDate));
    dispatch(onDayChange(itemId));
  };

  // Events - Fields
  const onMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(monthVar.getFullYear(), monthVar.getMonth() - 1, 1)
        : new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 1);

    // Update Redux Store
    dispatch(onMonthChange(newMonthVar.toString()));
  };

  const onSuggestionMonthChange = (value) => {
    // Update Redux Store
    dispatch(onMonthChange(value.toString()));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onMonthChange(currentMonth.toString()));
  };

  // Functions
  // Functions - Queries
  const getDaySuggestions = async () => {
    const results = await getDaySuggestionsFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      shopId,
      therapyId,
      monthVar
    );

    // Set States
    setDays(results.days ? results.days : null);

    if (results.days) {
      for (let item of results.days) {
        item.date_display = item.date ? moment(item.date).format("D日") : null;
      }
    }

    if (
      results.days &&
      dayId &&
      results.days.findIndex((item) => item.id === dayId) !== -1
    ) {
      return;
    }

    // Update Redux Store
    dispatch(onDateChange(null));
    dispatch(onDayChange(null));
  };

  // Life Cycle
  useEffect(() => {
    if (shopId && therapyId && month) {
      getDaySuggestions();
    } else {
      // Update Redux Store
      dispatch(onDateChange(null));
      dispatch(onDayChange(null));
    }
  }, [shopId, therapyId, month]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            日期
          </Typography>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          {!isMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                返回本月
              </Button>
            </div>
          )}
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="MM/yyyy"
              label="月份 (月/年)"
              onChange={onSuggestionMonthChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={month}
              views={["year", "month"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onMonthBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <DaySuggestionBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有日期可進行此療程
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DaySuggestionBoxesContainer;
