const initialState = {
  keyword: "",
  tabItem: "Patients",
  typeOfPatients: "Current",
};

export const patientsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_PATIENTS_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_PATIENTS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_PATIENTS_PAGE_TYPE_OF_PATIENTS_CHANGE":
      const { typeOfPatients } = action;
      return {
        ...state,
        typeOfPatients,
      };
    default:
      return state;
  }
};
