// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerListItem from "./leftDrawerListItem";

// Configs
import stylesConfig from "../../configs/stylesConfig";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";

// Material UI
// Components
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Icons
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import MedicalServicesRoundedIcon from "@mui/icons-material/MedicalServicesRounded";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { dividerStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerList() {
  // Hooks
  // React-Router
  const location = useLocation();
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();

  // Events
  // Events - Drawer Items
  const onDrawerItemClicked = (key) => {
    const path = getPagePath(key);

    // Navigate
    navigate(path);

    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <List>
      <ListItem sx={{ height: stylesConfig.leftDrawerHeight }}>
        <ListItemText primary={"預約系統"} />
      </ListItem>
      <Divider sx={dividerStyles} />
      {/* 預約 */}
      <LeftDrawerListItem
        // Render
        icon={<ContentPasteRoundedIcon />}
        itemKey={"Bookings"}
        location={location}
        pathnamesArr={["/bookings", "/createBooking"]}
        listItemText={"預約"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 病人 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentIndRoundedIcon />}
        itemKey={"Patients"}
        location={location}
        pathnamesArr={["/createPatient", "/patients", "/patientProfile"]}
        listItemText={"病人"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 療程 */}
      <LeftDrawerListItem
        // Render
        icon={<MedicalServicesRoundedIcon />}
        itemKey={"Therapies"}
        location={location}
        pathnamesArr={["/therapies"]}
        listItemText={"療程"}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
    </List>
  );
}

export default LeftDrawerList;
