const initialState = {
  brandId: null,
  brandItem: null,
  formSubmitCount: 0,
  shopId: null,
  tabItem: "Therapies",
  typeOfTherapiesAndRooms: "Current",
};

export const therapiesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_THERAPIES_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_THERAPIES_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_THERAPIES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_THERAPIES_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_THERAPIES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_THERAPIES_PAGE_TYPE_OF_THERAPIES_AND_ROOMS_CHANGE":
      const { typeOfTherapiesAndRooms } = action;
      return {
        ...state,
        typeOfTherapiesAndRooms,
      };
    default:
      return state;
  }
};
