// Configs
import apisConfig from "../configs/apisConfig";

export const createTherapyFetch = async (token, therapyName, duration) => {
  try {
    const queryRoute = "/therapy/createTherapy";

    const reqBody = {
      therapyName,
      duration,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreTherapyFetch = async (
  token,
  therapyId,
  typeOfTherapies
) => {
  try {
    const queryRoute = `/therapy/deleteOrRestoreTherapy/${therapyId}`;

    const reqBody = { typeOfTherapies };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTherapyFetch = async (
  token,
  therapyId,
  therapyName,
  duration
) => {
  try {
    const queryRoute = `/therapy/editTherapy/${therapyId}`;

    const reqBody = {
      therapyName,
      duration,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllTherapiesFetch = async (token, typeOfTherapies) => {
  try {
    const queryRoute = "/therapy/getAllTherapies";

    const reqBody = { typeOfTherapies };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllTherapiesByShopIdFetch = async (
  token,
  shopId,
  typeOfTherapies
) => {
  try {
    const queryRoute = `/therapy/getAllTherapiesByShopId/${shopId}`;

    const reqBody = { typeOfTherapies };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTherapyDetailsByIdFetch = async (token, therapyId) => {
  try {
    const queryRoute = `/therapy/getTherapyDetailsById/${therapyId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
