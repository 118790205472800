// Components
import StyledMenu from "../styledMenu";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function PatientMedicalRecordActionMenu(props) {
  // Props
  const {
    // States
    showPatientMedicalRecordActionMenu,
    // Set States
    setShowPatientMedicalRecordActionMenu,
    // Functions
    displayPatientMedicalRecordActionDialog,
    displayPatientMedicalRecordActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowPatientMedicalRecordActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayPatientMedicalRecordActionDialog("DeletePatientMedicalRecord");
        break;
      case "Edit":
        displayPatientMedicalRecordActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showPatientMedicalRecordActionMenu}
      open={Boolean(showPatientMedicalRecordActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        編輯
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        刪除
      </MenuItem>
    </StyledMenu>
  );
}

export default PatientMedicalRecordActionMenu;
