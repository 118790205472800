// Actions
import { onTimeSlotChange } from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import TimeSlotSuggestionBox from "./timeSlotSuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getTimeSlotSuggestionsFetch } from "../../../fetches/timeSlotFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function TimeSlotSuggestionBoxesContainer(props) {
  // Hooks
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const { bookingIdParam } = props;

  // Redux Store
  const dayId = useSelector((state) => state.createBookingPage.dayId);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const timeSlotId = useSelector((state) => state.createBookingPage.timeSlotId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [timeSlots, setTimeSlots] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = timeSlotId;
  const itemsArr = timeSlots;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onTimeSlotChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getTimeSlotSuggestions = async () => {
    const results = await getTimeSlotSuggestionsFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      shopId,
      therapyId,
      dayId
    );

    // Set States
    setTimeSlots(results.timeSlots ? results.timeSlots : null);

    if (results.timeSlots) {
      for (let item of results.timeSlots) {
        item.start_time = item.start_time
          ? moment(item.start_time).format("HH:mm")
          : null;
      }
    }

    if (
      results.timeSlots &&
      timeSlotId &&
      results.timeSlots.findIndex((item) => item.id === timeSlotId) !== -1
    ) {
      return;
    }

    // Update Redux Store
    dispatch(onTimeSlotChange(null));
  };

  // Life Cycle
  useEffect(() => {
    if (shopId && therapyId && dayId) {
      getTimeSlotSuggestions();
    } else {
      // Update Redux Store
      dispatch(onTimeSlotChange(null));
    }
  }, [shopId, therapyId, dayId]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            時段
          </Typography>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <TimeSlotSuggestionBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有時段可進行此療程
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TimeSlotSuggestionBoxesContainer;
