export function onBrandChange(brandId) {
  return {
    type: "ON_THERAPIES_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onBrandItemChange(brandItem) {
  return {
    type: "ON_THERAPIES_PAGE_BRAND_ITEM_CHANGE",
    brandItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_THERAPIES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onShopChange(shopId) {
  return {
    type: "ON_THERAPIES_PAGE_SHOP_CHANGE",
    shopId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_THERAPIES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfTherapiesAndRoomsChange(typeOfTherapiesAndRooms) {
  return {
    type: "ON_THERAPIES_PAGE_TYPE_OF_THERAPIES_AND_ROOMS_CHANGE",
    typeOfTherapiesAndRooms,
  };
}
