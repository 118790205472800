// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Boxes
import RoomBox from "./roomBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import RoomActionMenu from "../../menus/therapiesPage/roomActionMenu";
// Modals
import RoomActionModal from "../../modals/therapiesPage/roomActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  deleteOrRestoreRoomFetch,
  getAllRoomsByShopIdFetch,
} from "../../../fetches/roomFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function RoomBoxesContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();

  // Props
  const {
    // States
    hoveredRoomId,
  } = props;

  // Redux Store
  const shopId = useSelector((state) => state.therapiesPage.shopId);
  const token = useSelector((state) => state.staff.token);
  const typeOfTherapiesAndRooms = useSelector(
    (state) => state.therapiesPage.typeOfTherapiesAndRooms
  );

  // States
  // Data
  const [currentItemId, setCurrentItemId] = useState(null);
  const [rooms, setRooms] = useState(null);
  // Dialog
  const [roomActionDialogText, setRoomActionDialogText] = useState("");
  const [roomActionDialogType, setRoomActionDialogType] = useState(null);
  const [showRoomActionDialog, setShowRoomActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showRoomActionMenu, setShowRoomActionMenu] = useState(null);
  // Modals
  const [showRoomActionModal, setShowRoomActionModal] = useState(false);

  // Handle States
  const itemsArr = rooms;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);

    displayRoomActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowRoomActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onRoomActionDialogCanceled = () => {
    // Set States
    setShowRoomActionDialog(false);
  };

  const onRoomActionDialogConfirmed = () => {
    switch (roomActionDialogType) {
      case "DeleteRoom":
        deleteOrRestoreRoom();
        break;
      case "RestoreRoom":
        deleteOrRestoreRoom();
        break;
      default:
        break;
    }

    // Set States
    setShowRoomActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onRoomActionModalClosed = () => {
    getAllRooms();

    // Set States
    setShowRoomActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayRoomActionModal();
  };

  // Functions
  // Functions - Normal
  const displayRoomActionDialog = (roomActionType) => {
    // Set States
    setRoomActionDialogType(roomActionType);

    switch (roomActionType) {
      case "DeleteRoom":
        setRoomActionDialogText("確認要刪除 房間 嗎？");
        break;
      case "RestoreRoom":
        setRoomActionDialogText("確認要還原 房間 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowRoomActionDialog(true);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const displayRoomActionModal = () => {
    setShowRoomActionModal(true);
  };

  // Functions - Mutations
  const deleteOrRestoreRoom = async () => {
    const results = await deleteOrRestoreRoomFetch(
      token,
      currentItemId,
      typeOfTherapiesAndRooms
    );

    if (results.success) {
      getAllRooms();
    }
  };

  // Functions - Queries
  const getAllRooms = async () => {
    const results = await getAllRoomsByShopIdFetch(
      token,
      shopId,
      typeOfTherapiesAndRooms
    );

    // Set States
    setRooms(results.rooms ? results.rooms : null);
  };

  // Life Cycle
  useEffect(() => {
    if (shopId) {
      getAllRooms();
    }
  }, [shopId, typeOfTherapiesAndRooms]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onRoomActionDialogCanceled}
        onDialogConfirmed={onRoomActionDialogConfirmed}
        // States
        dialogText={roomActionDialogText}
        showDialog={showRoomActionDialog}
      />
      {/* Menu */}
      <RoomActionMenu
        // States
        showRoomActionMenu={showRoomActionMenu}
        // Set States
        setShowRoomActionMenu={setShowRoomActionMenu}
        // Fucntions
        displayRoomActionDialog={displayRoomActionDialog}
        displayRoomActionModal={displayRoomActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onRoomActionModalClosed}
        // States
        showModal={showRoomActionModal}
      >
        <RoomActionModal
          // States
          roomId={currentItemId}
          // Events
          onModalClosed={onRoomActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            房間
          </Typography>
          <Tooltip placement="right" title={"新增房間"}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item, index) => (
            <RoomBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              hoveredRoomId={hoveredRoomId}
              index={index}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有房間資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RoomBoxesContainer;
