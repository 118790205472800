// Components
// App Bar
import TopAppBar from "./components/appBar/topAppBar";
// Drawer
import LeftDrawerPerm from "./components/drawer/leftDrawerPerm";
import LeftDrawerTemp from "./components/drawer/leftDrawerTemp";
// Flt Act Btn
import ShowMenuBtn from "./components/fltActBtn/showMenuBtn";
// Router
import Router from "./router";
// Wrapper
import AppWrapper from "./components/wrappers/app/appWrapper";

// Configs
import stylesConfig from "./configs/stylesConfig";

// Helper Functions
import { getDesignTokens } from "./helperFunctions/getDesignTokens";

// Material UI
// Components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// Themes
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";

// React
import { useMemo } from "react";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { useLocation } from "react-router-dom";

// Styles
import { useAppStyles } from "./AppStyles";

function App() {
  // Hooks
  // Navigation
  const location = useLocation();
  // Styles
  const classes = useAppStyles();

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Theme
  const theme = useMemo(
    () => createTheme(getDesignTokens(themeMode)),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <AppWrapper>
          <div className="App">
            <Box
              className={
                location.pathname === "/"
                  ? classes.loginPageContainer
                  : classes.pageContainer
              }
              sx={{
                display: "flex",
                backgroundColor: stylesConfig.backgroundColor,
              }}
            >
              <CssBaseline />
              {location.pathname !== "/" && (
                <>
                  <LeftDrawerPerm />
                  <LeftDrawerTemp />
                  <TopAppBar />
                </>
              )}
              <Router />
              <ShowMenuBtn />
            </Box>
          </div>
        </AppWrapper>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
