// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const avatorButtonStyles = {
  height: 100,
  width: 100,
  borderRadius: 100,
};
export const avatorWithSrcStyles = {
  height: 100,
  width: 100,
};
export const avatorWithTextStyles = {
  bgcolor: stylesConfig.greyTextColor,
  color: stylesConfig.whiteTextColor,
  height: 100,
  width: 100,
};

export const useStandardItemBoxStyles = makeStyles((theme) =>
  createStyles({
    hoveredItemContainer: {
      alignItems: "flex-start",
      borderBottomStyle: "solid",
      borderColor: stylesConfig.greyBackGroundColor,
      borderWidth: 1,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 5px 4px 5px",
      padding: "5px 5px",
      width: "calc(100% - 10px)",
    },
    hoveredItemContainerWithoutBorder: {
      alignItems: "flex-start",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
    iconBtnText: {
      marginRight: "5px",
    },
    itemContainer: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
    itemContentContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    itemContentSubContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerLarge: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1.5,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerRight: {
      alignItems: "flex-end",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerRightLarge: {
      alignItems: "flex-end",
      display: "flex",
      flex: 1.5,
      flexDirection: "column",
      justifyContent: "center",
    },
    profilePhotoContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    selectedItemContainer: {
      alignItems: "flex-start",
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: stylesConfig.borderRadiusExtraSmall,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
  })
);
