// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Boxes
import TherapyBox from "./therapyBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import TherapyActionMenu from "../../menus/therapiesPage/therapyActionMenu";
// Modals
import TherapyActionModal from "../../modals/therapiesPage/therapyActionModal";
import ModalContainer from "../../modals/modalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  deleteOrRestoreTherapyFetch,
  getAllTherapiesFetch,
} from "../../../fetches/therapyFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useHorizontalItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/horizontalItemBoxesContainerStyles";

function TherapyBoxesContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useHorizontalItemBoxesContainerStyles();

  // Props
  const {
    // States
    hoveredTherapyId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);
  const typeOfTherapiesAndRooms = useSelector(
    (state) => state.therapiesPage.typeOfTherapiesAndRooms
  );

  // States
  // Data
  const [currentItemId, setCurrentItemId] = useState(null);
  const [therapies, setTherapies] = useState(null);
  // Dialog
  const [therapyActionDialogText, setTherapyActionDialogText] = useState("");
  const [therapyActionDialogType, setTherapyActionDialogType] = useState(null);
  const [showTherapyActionDialog, setShowTherapyActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showTherapyActionMenu, setShowTherapyActionMenu] = useState(null);
  // Modals
  const [showTherapyActionModal, setShowTherapyActionModal] = useState(false);

  // Handle States
  const itemsArr = therapies;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);

    displayTherapyActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowTherapyActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onTherapyActionDialogCanceled = () => {
    // Set States
    setShowTherapyActionDialog(false);
  };

  const onTherapyActionDialogConfirmed = () => {
    switch (therapyActionDialogType) {
      case "DeleteTherapy":
        deleteOrRestoreTherapy();
        break;
      case "RestoreTherapy":
        deleteOrRestoreTherapy();
        break;
      default:
        break;
    }

    // Set States
    setShowTherapyActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onTherapyActionModalClosed = () => {
    getAllTherapies();

    // Set States
    setShowTherapyActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayTherapyActionModal();
  };

  // Functions
  // Functions - Normal
  const displayTherapyActionDialog = (therapyActionType) => {
    // Set States
    setTherapyActionDialogType(therapyActionType);

    switch (therapyActionType) {
      case "DeleteTherapy":
        setTherapyActionDialogText("確認要刪除 療程 嗎？");
        break;
      case "RestoreTherapy":
        setTherapyActionDialogText("確認要還原 療程 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowTherapyActionDialog(true);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const displayTherapyActionModal = () => {
    setShowTherapyActionModal(true);
  };

  // Functions - Mutations
  const deleteOrRestoreTherapy = async () => {
    const results = await deleteOrRestoreTherapyFetch(
      token,
      currentItemId,
      typeOfTherapiesAndRooms
    );

    if (results.success) {
      getAllTherapies();
    }
  };

  // Functions - Queries
  const getAllTherapies = async () => {
    const results = await getAllTherapiesFetch(token, typeOfTherapiesAndRooms);

    // Set States
    setTherapies(results.therapies ? results.therapies : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllTherapies();
  }, [typeOfTherapiesAndRooms]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTherapyActionDialogCanceled}
        onDialogConfirmed={onTherapyActionDialogConfirmed}
        // States
        dialogText={therapyActionDialogText}
        showDialog={showTherapyActionDialog}
      />
      {/* Menu */}
      <TherapyActionMenu
        // States
        showTherapyActionMenu={showTherapyActionMenu}
        // Set States
        setShowTherapyActionMenu={setShowTherapyActionMenu}
        // Fucntions
        displayTherapyActionDialog={displayTherapyActionDialog}
        displayTherapyActionModal={displayTherapyActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onTherapyActionModalClosed}
        // States
        showModal={showTherapyActionModal}
      >
        <TherapyActionModal
          // States
          therapyId={currentItemId}
          // Events
          onModalClosed={onTherapyActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            療程
          </Typography>
          <Tooltip placement="right" title={"新增療程"}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {itemsArr && itemsArr[0] ? (
        <div className={classes.itemBoxesContainer}>
          {itemsArr.map((item) => (
            <TherapyBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              hoveredTherapyId={hoveredTherapyId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
        </div>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有療程資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TherapyBoxesContainer;
