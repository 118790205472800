// Components
import StyledMenu from "../styledMenu";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import PanoramaFishEyeRoundedIcon from "@mui/icons-material/PanoramaFishEyeRounded";

// Styles
import {
  roomToTherapyActionMenuItemLeftText,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function RoomToTherapyActionMenu(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    showRoomToTherapyActionMenu,
    // Set States
    setShowRoomToTherapyActionMenu,
    // Functions
    createOrEditRoomToTherapies,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowRoomToTherapyActionMenu(null);
  };

  const onMenuItemClicked = (itemType) => {
    onMenuClosed();

    createOrEditRoomToTherapies(itemType);
  };

  return (
    <StyledMenu
      // States
      anchorEl={showRoomToTherapyActionMenu}
      open={Boolean(showRoomToTherapyActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked(true)}>
        <div className={classes.menuItem}>
          <PanoramaFishEyeRoundedIcon />
          <Typography
            align={"center"}
            variant="body1"
            sx={roomToTherapyActionMenuItemLeftText}
          >
            可以進行療程
          </Typography>
        </div>
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked(false)}>
        <div className={classes.menuItem}>
          <ClearRoundedIcon />
          <Typography
            align={"center"}
            variant="body1"
            sx={roomToTherapyActionMenuItemLeftText}
          >
            不可進行療程
          </Typography>
        </div>
      </MenuItem>
    </StyledMenu>
  );
}

export default RoomToTherapyActionMenu;
