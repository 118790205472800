// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/patientProfilePage/patientProfilePageActions";

// Components
// Boxes
import PatientMedicalRecordBox from "./patientMedicalRecordBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PatientMedicalRecordActionMenu from "../../menus/patientProfilePage/patientMedicalRecordActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import PatientMedicalRecordActionModal from "../../modals/patientProfilePage/patientMedicalRecordActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  deleteMedicalRecordFetch,
  getAllMedicalRecordsByPatientIdFetch,
} from "../../../fetches/medicalRecordFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PatientMedicalRecordBoxesContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const patientId = useSelector((state) => state.patientProfilePage.patientId);
  const formSubmitCount = useSelector(
    (state) => state.patientProfilePage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [patientMedicalRecords, setPatientMedicalRecords] = useState(null);
  // Dialog
  const [
    patientMedicalRecordActionDialogText,
    setPatientMedicalRecordActionDialogText,
  ] = useState("");
  const [
    patientMedicalRecordActionDialogType,
    setPatientMedicalRecordActionDialogType,
  ] = useState(null);
  const [
    showPatientMedicalRecordActionDialog,
    setShowPatientMedicalRecordActionDialog,
  ] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showPatientMedicalRecordActionMenu,
    setShowPatientMedicalRecordActionMenu,
  ] = useState(null);
  // Modals
  const [
    showPatientMedicalRecordActionModal,
    setShowPatientMedicalRecordActionModal,
  ] = useState(false);

  // Handle States
  const itemsArr = patientMedicalRecords;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayPatientMedicalRecordActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowPatientMedicalRecordActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowPatientMedicalRecordActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPatientMedicalRecordActionDialogCanceled = () => {
    // Set States
    setShowPatientMedicalRecordActionDialog(false);
  };

  const onPatientMedicalRecordActionDialogConfirmed = () => {
    switch (patientMedicalRecordActionDialogType) {
      case "DeletePatientMedicalRecord":
        deleteMedicalRecord();
        break;
      default:
        break;
    }

    // Set States
    setShowPatientMedicalRecordActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onPatientMedicalRecordActionModalClosed = () => {
    // Set States
    setShowPatientMedicalRecordActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayPatientMedicalRecordActionModal();
  };

  // Functions
  // Functions - Normal
  const displayPatientMedicalRecordActionDialog = (
    patientMedicalRecordActionType
  ) => {
    // Set States
    setPatientMedicalRecordActionDialogType(patientMedicalRecordActionType);

    switch (patientMedicalRecordActionType) {
      case "DeletePatientMedicalRecord":
        setPatientMedicalRecordActionDialogText("確認要刪除 病歷 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientMedicalRecordActionDialog(true);
  };

  const displayPatientMedicalRecordActionModal = () => {
    setShowPatientMedicalRecordActionModal(true);
  };

  // Functions - Mutations
  const deleteMedicalRecord = async () => {
    const results = await deleteMedicalRecordFetch(token, currentItemId);

    if (results.success) {
      getAllMedicalRecordsByPatientId();
    }
  };

  // Functions - Queries
  const getAllMedicalRecordsByPatientId = async () => {
    const results = await getAllMedicalRecordsByPatientIdFetch(
      token,
      patientId
    );

    // Set States
    setPatientMedicalRecords(
      results.medicalRecords ? results.medicalRecords : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (patientId) {
      getAllMedicalRecordsByPatientId();
    }
  }, [formSubmitCount, patientId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientMedicalRecordActionDialogCanceled}
        onDialogConfirmed={onPatientMedicalRecordActionDialogConfirmed}
        // States
        dialogText={patientMedicalRecordActionDialogText}
        showDialog={showPatientMedicalRecordActionDialog}
      />
      {/* Menu */}
      <PatientMedicalRecordActionMenu
        // States
        showPatientMedicalRecordActionMenu={showPatientMedicalRecordActionMenu}
        // Set States
        setShowPatientMedicalRecordActionMenu={
          setShowPatientMedicalRecordActionMenu
        }
        // Functions
        displayPatientMedicalRecordActionDialog={
          displayPatientMedicalRecordActionDialog
        }
        displayPatientMedicalRecordActionModal={
          displayPatientMedicalRecordActionModal
        }
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onPatientMedicalRecordActionModalClosed}
        // States
        showModal={showPatientMedicalRecordActionModal}
      >
        <PatientMedicalRecordActionModal
          // Events
          onModalClosed={onPatientMedicalRecordActionModalClosed}
          // States
          medicalRecordId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            病歷
          </Typography>
          <Tooltip placement="right" title="新增病歷">
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <PatientMedicalRecordBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有病歷資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PatientMedicalRecordBoxesContainer;
