// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Helper Functions
import { getTimeSlotBoxStylesType } from "../../../helperFunctions/getTimeSlotBoxStylesType";

// Material UI
// Components
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function TimeSlotToRoomBox(props) {
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    hoveredTimeSlotIdsArr,
    hoveredRoomId,
    isEditMode,
    isHoveredRoomCapable,
    item,
    selectedBookingId,
    selectedRoomId,
    selectedTherapyId,
    selectedTimeSlotId,
    timeSlotId,
    timeSlotStartTime,
    // Events
    onItemClicked,
    onItemRightClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const hoveredItem =
    hoveredRoomId &&
    hoveredRoomId === item.room_id &&
    hoveredTimeSlotIdsArr[0] &&
    hoveredTimeSlotIdsArr.find((item) => item.id === timeSlotId);
  const isBottom = hoveredItem && hoveredItem.bottom;
  const isHovered = selectedBookingId
    ? isEditMode && hoveredItem
    : (selectedTherapyId || !item.booking_id) && hoveredItem;
  const isBookingSelected =
    selectedBookingId && selectedBookingId === item.booking_id;
  const isCompleted =
    item.booking_status_type_id && item.booking_status_type_id === 3;
  const isSelected =
    selectedRoomId &&
    selectedRoomId === item.room_id &&
    selectedTimeSlotId &&
    selectedTimeSlotId === timeSlotId;
  const isTop = hoveredItem && hoveredItem.top;
  const stylesType = getTimeSlotBoxStylesType(
    item.booking_id,
    item.booking_to_time_slot_type_id,
    isBookingSelected,
    isHoveredRoomCapable,
    isSelected,
    isHovered,
    isBottom,
    isTop
  );

  return (
    <Tooltip
      placement="right"
      title={
        item.booking_id
          ? `${item.start_time} - ${item.end_time}`
          : timeSlotStartTime
      }
    >
      <div
        className={
          stylesType
            ? stylesType.isSelected
              ? stylesType.type === "Top"
                ? classes.selectedCellBoxTimeSlotTop
                : stylesType.type === "Middle"
                ? classes.selectedCellBoxTimeSlotMiddle
                : stylesType.type === "Bottom"
                ? classes.selectedCellBoxTimeSlotBottom
                : classes.selectedCellBoxTimeSlotSingle
              : stylesType.isForbidden
              ? stylesType.type === "Top"
                ? classes.hoveredCellBoxTimeSlotTopForbidden
                : stylesType.type === "Middle"
                ? classes.hoveredCellBoxTimeSlotMiddleForbidden
                : stylesType.type === "Bottom"
                ? classes.hoveredCellBoxTimeSlotBottomForbidden
                : classes.hoveredCellBoxTimeSlotSingleForbidden
              : isCompleted
              ? stylesType.type === "Top"
                ? classes.hoveredCellBoxTimeSlotTopCompleted
                : stylesType.type === "Middle"
                ? classes.hoveredCellBoxTimeSlotMiddleCompleted
                : stylesType.type === "Bottom"
                ? classes.hoveredCellBoxTimeSlotBottomCompleted
                : classes.hoveredCellBoxTimeSlotSingleCompleted
              : stylesType.type === "Top"
              ? classes.hoveredCellBoxTimeSlotTop
              : stylesType.type === "Middle"
              ? classes.hoveredCellBoxTimeSlotMiddle
              : stylesType.type === "Bottom"
              ? classes.hoveredCellBoxTimeSlotBottom
              : classes.hoveredCellBoxTimeSlotSingle
            : classes.cellBoxTimeSlot
        }
        onClick={() => onItemClicked(timeSlotId, item.room_id, item.booking_id)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(
            timeSlotId,
            item.room_id,
            item.booking_id,
            item.booking_status_type_id,
            item.therapy_id,
            item.num_of_time_slot,
            event.currentTarget
          );
        }}
        onMouseEnter={() => {
          onMouseEnterItem(timeSlotId, item.room_id);
        }}
        onMouseLeave={() => {
          onMouseLeaveItem();
        }}
      >
        {item.booking_id && item.booking_to_time_slot_type_id === 1 && (
          <Typography
            align={"center"}
            color={stylesConfig.whiteTextColor}
            variant="body1"
          >
            {item.therapy_name}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
}

export default TimeSlotToRoomBox;
