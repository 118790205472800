export function onKeywordChange(keyword) {
  return {
    type: "ON_PATIENTS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_PATIENTS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfPatientsChange(typeOfPatients) {
  return {
    type: "ON_PATIENTS_PAGE_TYPE_OF_PATIENTS_CHANGE",
    typeOfPatients,
  };
}
