// Actions
import {
  onPatientChange,
  onTabItemChange,
} from "../../../../redux/pages/patientProfilePage/patientProfilePageActions";

// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function BookingActionModalPatientInfo(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  // States
  // Hover
  const [isHovered, setIsHovered] = useState(null);

  // Events
  // Events - Box
  const onItemClicked = () => {
    // Update Redux Store
    dispatch(onPatientChange(item.patient_id));
    dispatch(onTabItemChange("Bookings"));

    // Navigate
    navigate("/patientProfile");
  };

  // Events - Hover
  const onMouseEnterItem = () => {
    // Set States
    setIsHovered(true);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setIsHovered(false);
  };

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onClick={onItemClicked}
      onMouseEnter={onMouseEnterItem}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            病人
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {`${item.full_name_en} (${item.gender_type_name_ch} / ${item.age} 歲)`}
          </Typography>
        </div>
      </div>
      <Typography
        align={"left"}
        color={stylesConfig.greyTextColor}
        variant="body2"
      >
        {`電話 - ${item.phone_num}`}
      </Typography>
    </div>
  );
}

export default BookingActionModalPatientInfo;
