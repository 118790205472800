// Dummy Datum
import { handledByDummyData } from "../../../dummyDatum/handledByDummyData";

const today = new Date();

const initialState = {
  brandId: null,
  brandItem: null,
  date: today.toString(),
  formSubmitCount: 0,
  handledById: handledByDummyData[0].id,
  handledByDisplayText: handledByDummyData[0].full_name_en,
  shopId: null,
  tabItem: "Bookings",
};

export const bookingsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_BOOKINGS_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_BOOKINGS_PAGE_BRAND_ITEM_CHANGE":
      const { brandItem } = action;
      return {
        ...state,
        brandItem,
      };
    case "ON_BOOKINGS_PAGE_DATE_CHANGE":
      const { date } = action;
      return {
        ...state,
        date,
      };
    case "ON_BOOKINGS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_BOOKINGS_PAGE_HANDLED_BY_CHANGE":
      const { handledById, handledByDisplayText } = action;
      return {
        ...state,
        handledById,
        handledByDisplayText,
      };
    case "ON_BOOKINGS_PAGE_SHOP_CHANGE":
      const { shopId } = action;
      return {
        ...state,
        shopId,
      };
    case "ON_BOOKINGS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
