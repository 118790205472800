// Configs
import apisConfig from "../configs/apisConfig";

export const createOrEditShopToTherapiesFetch = async (
  token,
  shopToTherapies,
  isCapable
) => {
  try {
    const queryRoute = "/shopToTherapy/createOrEditShopToTherapies";

    const reqBody = { shopToTherapies, isCapable };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllShopToTherapiesByBrandIdFetch = async (token, brandId) => {
  try {
    const queryRoute = `/shopToTherapy/getAllShopToTherapiesByBrandId/${brandId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
