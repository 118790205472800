// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const formSelectBoxContainerStyles = {
  m: 1,
  minWidth: 120,
};

export const containerSubmitBtnStyles = { width: "100%" };
export const formSubmitBtnStyles = { marginTop: "10px", width: "40%" };
export const formSubmitBtnGroupStyles = { marginTop: "10px", width: "70%" };
export const formSubmitBtnGroupBtnStyles = { flex: 1 };
export const formUploadBtnStyles = {
  height: "80px",
  marginTop: "10px",
  width: "80%",
};
export const mapViewContainerStyles = { height: "500px", width: "100%" };

export const selectBoxContainerStyles = {
  width: "100%",
};

export const useStandardItemBoxesContainerStyles = makeStyles((theme) =>
  createStyles({
    // Box
    autoCompleteContainer: {
      marginBottom: "10px",
      padding: "10px 10px",
      width: "100%",
    },
    containerSubmitBtnBox: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px",
      width: "100%",
    },
    contentBox: {
      alignItems: "flex-start",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      borderRadius: stylesConfig.borderRadiusSmall,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "20px 20px",
      position: "relative",
    },
    contentBoxWithMaxWidth: {
      alignItems: "flex-start",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      borderRadius: stylesConfig.borderRadiusSmall,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "20px 20px",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        maxWidth: `calc(100vw - ${stylesConfig.leftDrawerWidth + 40}px)`,
      },
    },
    emptyDataContainer: {
      margin: "10px 10px",
    },
    paginationStack: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
      width: "100%",
    },
    titleContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "5px",
      width: "100%",
    },
    titleContainerWithoutMargin: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    titleTextContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    // Form
    formAutoCompleteContainer: {
      margin: "10px 0px",
      width: "100%",
    },
    formAutoCompleteLeftContainer: {
      margin: "10px 0px",
      minWidth: 150,
    },
    formAutoCompleteStickyContainer: {
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      margin: "10px 0px",
      position: "sticky",
      top: 0,
      width: "100%",
      zIndex: 1,
    },
    formContainer: {
      padding: "10px 10px",
    },
    formDatePickerContainer: {
      margin: "10px 0px",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    formSubmitBtnContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    formSubmitBtnStickyContainer: {
      alignItems: "center",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      position: "sticky",
      width: "100%",
      zIndex: 1,
    },
    formSwitchContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    formTwinIconBtnContainerLeft: {
      display: "flex",
      flexDirection: "row",
      marginRight: 10,
    },
    formTwinIconBtnContainerRight: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 10,
    },
    formTwinInputContainerText: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "0px 0px 15px 0px",
    },
    // Modal
    modalContainerLarge: {
      maxWidth: "600px",
      width: "70vw",
    },
    modalContainerSmall: {
      maxWidth: "400px",
      width: "70vw",
    },
  })
);
