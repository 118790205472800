// Components
// Boxes
import PatientSuggestionBox from "../../boxes/searchModal/patientSuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getPatientSuggestionByKeywordFetch } from "../../../fetches/searchFetches";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useRef, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SearchPatientModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onItemClicked,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // Refs
  const inputRef = useRef(null);

  // States
  // Data
  const [suggestions, setSuggestions] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Text Fields
  const [keyword, setKeyword] = useState("");

  // Handle States
  const itemsArr = suggestions;

  // Events
  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    switch (field) {
      case "keyword":
        setKeyword(value);
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Normal
  const getSuggestionsDebounce = debounce((keywordStr) => {
    getPatientSuggestionByKeyword(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getPatientSuggestionByKeyword = async (keywordStr) => {
    const results = await getPatientSuggestionByKeywordFetch(token, keywordStr);

    // Set States
    setSuggestions(results.suggestions ? results.suggestions : null);
  };

  // Life Cycle
  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (keyword) {
      getSuggestionsDebounce(keyword);
    }
  }, [keyword]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid
            className={classes.formAutoCompleteStickyContainer}
            item
            xs={12}
          >
            <TextField
              autoFocus
              fullWidth
              label="關鍵字"
              margin="dense"
              name="keyword"
              onChange={(event) =>
                onInputFieldChange("keyword", event.target.value)
              }
              ref={inputRef}
              required
              value={keyword}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <PatientSuggestionBox
                  key={item.id}
                  // States
                  hoveredItemId={hoveredItemId}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  未有相關病人
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SearchPatientModal;
