// Components
import StyledMenu from "../styledMenu";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function PatientActionMenu(props) {
  // Props
  const {
    // States
    showPatientActionMenu,
    // Set States
    setShowPatientActionMenu,
    // Functions
    displayPatientActionDialog,
  } = props;

  // Redux Store
  const typeOfPatients = useSelector(
    (state) => state.patientsPage.typeOfPatients
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowPatientActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayPatientActionDialog("DeletePatient");
        break;
      case "Restore":
        displayPatientActionDialog("RestorePatient");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showPatientActionMenu}
      open={Boolean(showPatientActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfPatients === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          刪除
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          還原
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default PatientActionMenu;
