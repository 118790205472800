// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Tables
import RoomToTherapyTable from "./roomToTherapyTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getAllRoomToTherapiesByShopIdFetch } from "../../../fetches/roomToTherapyFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function RoomToTherapyTableContainer(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    editedRoomToTherapies,
    hoveredRoomId,
    selectedRoomToTherapies,
    // Set States
    setEditedRoomToTherapies,
    setHoveredRoomId,
    setSelectedRoomToTherapies,
    // Functions
    createOrEditRoomToTherapies,
  } = props;

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.therapiesPage.formSubmitCount
  );
  const shopId = useSelector((state) => state.therapiesPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [therapies, setTherapies] = useState(null);
  const [roomWithTherapies, setRoomWithTherapies] = useState(null);
  // Redner
  const [isLoading, setIsLoading] = useState(false);
  // Select Mode
  const [isSelectMode, setIsSelectMode] = useState(false);

  // Events
  // Events - Box
  const onMouseUp = () => {
    setIsSelectMode(false);
  };

  // Events - Title
  const onClearSelectedRoomToTherapiesBtnClicked = () => {
    clearSelectedRoomToTherapies();
  };

  // Functions
  // Functions - Normal
  const clearAll = () => {
    clearEditedRoomToTherapies();
    clearSelectedRoomToTherapies();
  };

  const clearEditedRoomToTherapies = () => {
    // Set States
    setEditedRoomToTherapies([]);
  };

  const clearSelectedRoomToTherapies = () => {
    // Set States
    setIsSelectMode(false);
    setSelectedRoomToTherapies([]);
  };

  const getAllRoomToTherapiesDebounce = debounce((shopIdVar) => {
    getAllRoomToTherapiesByShopId(shopIdVar);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getAllRoomToTherapiesByShopId = async (shopIdVar) => {
    const results = await getAllRoomToTherapiesByShopIdFetch(token, shopIdVar);

    // Set States
    setRoomWithTherapies(
      results.roomWithTherapies ? results.roomWithTherapies : null
    );
    setTherapies(results.therapies ? results.therapies : null);

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    clearAll();
  }, [shopId]);

  useEffect(() => {
    if (shopId) {
      // Set States
      setIsLoading(true);

      getAllRoomToTherapiesDebounce(shopId);
    }

    clearSelectedRoomToTherapies();
  }, [formSubmitCount, shopId]);

  return (
    <div className={classes.contentBoxWithMaxWidth} onMouseUp={onMouseUp}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            療程
          </Typography>
          <div className={classes.formTwinIconBtnContainerRight}>
            {selectedRoomToTherapies[0] && (
              <Button
                endIcon={<ClearRoundedIcon />}
                onClick={onClearSelectedRoomToTherapiesBtnClicked}
                variant="text"
              >
                清除已選擇紀錄
              </Button>
            )}
          </div>
        </div>
      </div>
      {therapies && roomWithTherapies ? (
        // Table
        <RoomToTherapyTable
          // States
          editedRoomToTherapies={editedRoomToTherapies}
          hoveredRoomId={hoveredRoomId}
          isSelectMode={isSelectMode}
          roomWithTherapies={roomWithTherapies}
          selectedRoomToTherapies={selectedRoomToTherapies}
          therapies={therapies}
          // Set States
          setHoveredRoomId={setHoveredRoomId}
          setIsSelectMode={setIsSelectMode}
          setSelectedRoomToTherapies={setSelectedRoomToTherapies}
          // Functions
          createOrEditRoomToTherapies={createOrEditRoomToTherapies}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有療程資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RoomToTherapyTableContainer;
