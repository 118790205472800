// Components
// Boxes
import PatientIntroBasicInfoBox from "./patientIntroBasicInfoBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { getPatientBasicInfoByIdFetch } from "../../../fetches/patientFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PatientIntroBoxesContainer() {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const patientId = useSelector((state) => state.patientProfilePage.patientId);
  const formSubmitCount = useSelector(
    (state) => state.patientProfilePage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [patientBasicInfo, setPatientBasicInfo] = useState(null);

  // Handle States
  const item = patientBasicInfo;

  // Functions
  // Functions - Queries
  const getPatientBasicInfoById = async () => {
    const results = await getPatientBasicInfoByIdFetch(token, patientId);

    if (results.patientBasicInfo) {
      results.patientBasicInfo.date_registered = moment(
        results.patientBasicInfo.date_registered
      ).format("YYYY年 MM月 DD日");
    }

    // Set States
    setPatientBasicInfo(
      results.patientBasicInfo ? results.patientBasicInfo : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (patientId) {
      getPatientBasicInfoById();
    }
  }, [formSubmitCount, patientId]);

  return (
    <div className={classes.contentBox}>
      {/* Content */}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            病人
          </Typography>
        </div>
      </div>
      {/* Content */}
      {item ? (
        <PatientIntroBasicInfoBox
          // States
          item={item}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有病人資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PatientIntroBoxesContainer;
