// Pages
import BookingsPage from "./pages/bookingsPage";
import CreateBookingPage from "./pages/createBookingPage";
import CreatePatientPage from "./pages/createPatientPage";
import LoginPage from "./pages/loginPage";
import PatientsPage from "./pages/patientsPage";
import PatientProfilePage from "./pages/patientProfilePage";
import TherapiesPage from "./pages/therapiesPage";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { Navigate, Route, Routes } from "react-router-dom";

function Router() {
  // Redux Store
  const token = useSelector((state) => state.staff.token);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/bookings"
        element={token ? <BookingsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/createBooking"
        element={token ? <CreateBookingPage /> : <Navigate to="/" />}
      />
      <Route
        path="/createPatient"
        element={token ? <CreatePatientPage /> : <Navigate to="/" />}
      />
      <Route
        path="/patients"
        element={token ? <PatientsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/patientProfile"
        element={token ? <PatientProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/therapies"
        element={token ? <TherapiesPage /> : <Navigate to="/" />}
      />
    </Routes>
  );
}

export default Router;
