export const durationOptions = [
  { value: 5, text: "5分鐘" },
  { value: 10, text: "10分鐘" },
  { value: 15, text: "15分鐘" },
  { value: 20, text: "20分鐘" },
  { value: 25, text: "25分鐘" },
  { value: 30, text: "30分鐘" },
  { value: 35, text: "35分鐘" },
  { value: 40, text: "40分鐘" },
  { value: 45, text: "45分鐘" },
  { value: 50, text: "50分鐘" },
  { value: 55, text: "55分鐘" },
  { value: 60, text: "60分鐘" },
  { value: 65, text: "65分鐘" },
  { value: 70, text: "70分鐘" },
  { value: 75, text: "75分鐘" },
  { value: 80, text: "80分鐘" },
  { value: 85, text: "85分鐘" },
  { value: 90, text: "90分鐘" },
  { value: 95, text: "95分鐘" },
  { value: 100, text: "100分鐘" },
  { value: 105, text: "105分鐘" },
  { value: 110, text: "110分鐘" },
  { value: 115, text: "115分鐘" },
  { value: 120, text: "120分鐘" },
  { value: 125, text: "125分鐘" },
  { value: 130, text: "130分鐘" },
  { value: 135, text: "135分鐘" },
  { value: 140, text: "140分鐘" },
  { value: 145, text: "145分鐘" },
  { value: 150, text: "150分鐘" },
  { value: 155, text: "155分鐘" },
  { value: 160, text: "160分鐘" },
  { value: 165, text: "165分鐘" },
  { value: 170, text: "170分鐘" },
  { value: 175, text: "175分鐘" },
  { value: 180, text: "180分鐘" },
];
