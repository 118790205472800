// Actions
import {
  onPatientChange,
  onTabItemChange,
} from "../../../redux/pages/patientProfilePage/patientProfilePageActions";

// Components
// Boxes
import PatientBox from "./patientBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PatientActionMenu from "../../menus/patientsPage/patientActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  deleteOrRestorePatientFetch,
  getAllPatientsWithPaginationFetch,
} from "../../../fetches/patientFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function PatientBoxesContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.patientsPage.keyword);
  const token = useSelector((state) => state.staff.token);
  const typeOfPatients = useSelector(
    (state) => state.patientsPage.typeOfPatients
  );

  // States
  // Data
  const [patients, setPatients] = useState(null);
  // Dialog
  const [patientActionDialogText, setPatientActionDialogText] = useState("");
  const [patientActionDialogType, setPatientActionDialogType] = useState(null);
  const [showPatientActionDialog, setShowPatientActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showPatientActionMenu, setShowPatientActionMenu] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  // Handle States
  const currentItemId = selectedPatientId;
  const itemsArr = patients;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onPatientChange(itemId));
    dispatch(onTabItemChange("Bookings"));

    // Navigate
    navigate("/patientProfile");
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedPatientId(itemId);
    setShowPatientActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedPatientId(itemId);
    setShowPatientActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPatientActionDialogCanceled = () => {
    // Set States
    setShowPatientActionDialog(false);
  };

  const onPatientActionDialogConfirmed = () => {
    switch (patientActionDialogType) {
      case "DeletePatient":
        deleteOrRestorePatient();
        break;
      case "RestorePatient":
        deleteOrRestorePatient();
        break;
      default:
        break;
    }

    // Set States
    setShowPatientActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Navigate
    navigate("/createPatient");
  };

  // Functions
  // Functions - Normal
  const displayPatientActionDialog = (patientActionType) => {
    // Set States
    setPatientActionDialogType(patientActionType);

    switch (patientActionType) {
      case "DeletePatient":
        setPatientActionDialogText("確認要刪除 病人 嗎？");
        break;
      case "RestorePatient":
        setPatientActionDialogText("確認要還原 病人 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowPatientActionDialog(true);
  };

  const getPatientsDebounce = debounce((keywordStr) => {
    getAllPatientsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  const resetPage = () => {
    // Set States
    setPageNum(1);
  };

  // Functions - Mutations
  const deleteOrRestorePatient = async () => {
    const results = await deleteOrRestorePatientFetch(
      token,
      currentItemId,
      typeOfPatients
    );

    if (results.success) {
      getAllPatientsWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllPatientsWithPagination = async (keywordStr) => {
    const results = await getAllPatientsWithPaginationFetch(
      token,
      typeOfPatients,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.patients) {
      for (let item of results.patients) {
        item.date_registered = item.date_registered
          ? moment(item.date_registered).format("YYYY年 MM月 DD日")
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setPatients(results.patients ? results.patients : null);

    if (results.pageCount && !results.patients) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetPage();
  }, [typeOfPatients, keyword]);

  useEffect(() => {
    getPatientsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllPatientsWithPagination(keyword);
  }, [typeOfPatients, pageNum]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onPatientActionDialogCanceled}
        onDialogConfirmed={onPatientActionDialogConfirmed}
        // States
        dialogText={patientActionDialogText}
        showDialog={showPatientActionDialog}
      />
      {/* Menu */}
      <PatientActionMenu
        // States
        showPatientActionMenu={showPatientActionMenu}
        // Set States
        setShowPatientActionMenu={setShowPatientActionMenu}
        // Fucntions
        displayPatientActionDialog={displayPatientActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            病人
          </Typography>
          <Tooltip placement="right" title="新增病人">
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <PatientBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有病人
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PatientBoxesContainer;
