// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { durationOptions } from "../../../consts/therapyDetailFormContentConsts";

// Fetches
import {
  createTherapyFetch,
  editTherapyFetch,
  getTherapyDetailsByIdFetch,
} from "../../../fetches/therapyFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TherapyActionModal(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Props
  const {
    // States
    therapyId,
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [therapyActionDialogText, setTherapyActionDialogText] = useState("");
  const [therapyActionDialogType, setTherapyActionDialogType] = useState(null);
  const [showTherapyActionDialog, setShowTherapyActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [durationField, setDurationField] = useState(null);
  // Text Fields
  const [therapyName, setTherapyName] = useState("");

  // Events
  // Events - Dialogs
  const onTherapyActionDialogCanceled = () => {
    // Set States
    setShowTherapyActionDialog(false);
  };

  const onTherapyActionDialogConfirmed = () => {
    switch (therapyActionDialogType) {
      case "CreateTherapy":
        createTherapy();
        break;
      case "EditTherapy":
        editTherapy();
        break;
      default:
        break;
    }

    // Set States
    setShowTherapyActionDialog(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "durationField":
        setDurationField(value);
        break;
      case "therapyName":
        setTherapyName(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!durationField) {
      addToErrorFields("durationField", "請先填寫 時長");
      isError = true;
    }

    if (!therapyName) {
      addToErrorFields("therapyName", "請先填寫 療程名稱");
      isError = true;
    }

    if (isError) {
      return;
    }

    displayTherapyActionDialog(therapyId ? "EditTherapy" : "CreateTherapy");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayTherapyActionDialog = (therapyActionType) => {
    // Set States
    setTherapyActionDialogType(therapyActionType);

    switch (therapyActionType) {
      case "CreateTherapy":
        setTherapyActionDialogText("確認要新增 療程 嗎？");
        break;
      case "EditTherapy":
        setTherapyActionDialogText("確認要編輯 療程 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowTherapyActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createTherapy = async () => {
    const results = await createTherapyFetch(
      token,
      therapyName,
      durationField ? durationField.value : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 療程 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  const editTherapy = async () => {
    const results = await editTherapyFetch(
      token,
      therapyId,
      therapyName,
      durationField ? durationField.value : null
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", "此 療程 已存在");
    } else {
      showFormAlert("error", "未能提交");
    }
  };

  // Functions = Queries
  const getTherapyDetailsById = async () => {
    const results = await getTherapyDetailsByIdFetch(token, therapyId);

    if (results.therapyDetails) {
      const { therapy_name, duration } = results.therapyDetails;

      // Set States
      setTherapyName(therapy_name);
      setDurationField(
        duration
          ? durationOptions.find((item) => item.value === duration)
          : null
      );
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (therapyId) {
      getTherapyDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [therapyId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTherapyActionDialogCanceled}
        onDialogConfirmed={onTherapyActionDialogConfirmed}
        // States
        dialogText={therapyActionDialogText}
        showDialog={showTherapyActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {therapyId ? "編輯療程" : "新增療程"}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("therapyName")}
              fullWidth
              helperText={getErrorFieldMessage("therapyName")}
              label="療程名稱"
              margin="dense"
              name="therapyName"
              onChange={(event) =>
                onInputFieldChange("therapyName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              required
              value={therapyName}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.text}
              onChange={(event, value) =>
                onInputFieldChange("durationField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={durationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("durationField")}
                  helperText={getErrorFieldMessage("durationField")}
                  label={"時長"}
                  variant="standard"
                />
              )}
              value={durationField}
            />
          </Grid>
          <Grid item xs={12}>
            <SpacingBox
              // Render
              height={stylesConfig.spacingBoxMarginBottomSmall}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              確定
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default TherapyActionModal;
