// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function BookingBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.brand_id, item.shop_id, item.date)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(
            item.id,
            item.booking_status_type_id,
            item.therapy_id,
            item.area_id,
            item.district_id,
            item.brand_id,
            item.shop_id,
            item.date,
            item.num_of_time_slot,
            event.currentTarget
          );
        }}
      >
        <div className={classes.itemContentSubContainerLarge}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            {item.therapy_name}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.booking_status_type_name_ch}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            {item.shop_code}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.room_name}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerLarge}>
          <Typography
            align={"left"}
            color={isHovered ? stylesConfig.blueTextColor : null}
            variant="body1"
          >
            {item.date_display}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.start_time}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) =>
            onItemShowMenuBtnClicked(
              item.id,
              item.booking_status_type_id,
              item.therapy_id,
              item.area_id,
              item.district_id,
              item.brand_id,
              item.shop_id,
              item.date,
              item.num_of_time_slot,
              event.currentTarget
            )
          }
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default BookingBox;
