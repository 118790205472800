// Components
import StyledMenu from "../styledMenu";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

function BookingActionMenu(props) {
  // Props
  const {
    // States
    isRelocationEnabled,
    showBookingActionMenu,
    // Set States
    setShowBookingActionMenu,
    // Events
    onBookingActionMenuItemClikced,
    // Functions
    clearAll,
    displayBookingActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    clearAll();

    // Set States
    setShowBookingActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    // Set States
    setShowBookingActionMenu(null);

    switch (key) {
      case "Cancel":
        displayBookingActionDialog("CancelBooking");
        break;
      case "Edit":
        onBookingActionMenuItemClikced(key);
        break;
      case "Relocate":
        if (isRelocationEnabled) {
          onBookingActionMenuItemClikced(key);
        }
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showBookingActionMenu}
      open={Boolean(showBookingActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        修改
      </MenuItem>
      {isRelocationEnabled && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Relocate")}>
          <RestoreRoundedIcon />
          調動
        </MenuItem>
      )}
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Cancel")}>
        <DeleteRoundedIcon />
        取消
      </MenuItem>
    </StyledMenu>
  );
}

export default BookingActionMenu;
