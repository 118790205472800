// Configs
import apisConfig from "../configs/apisConfig";

export const getAllTimeSlotsByShopIdAndDateFetch = async (
  token,
  shopId,
  date
) => {
  try {
    const queryRoute = `/timeSlot/getAllTimeSlotsByShopIdAndDate/${shopId}`;

    const reqBody = { date };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getNearestTimeSlotsByShopIdAndDateFetch = async (
  token,
  shopId,
  date
) => {
  try {
    const queryRoute = `/timeSlot/getNearestTimeSlotsByShopIdAndDate/${shopId}`;

    const reqBody = { date };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTimeSlotSuggestionsFetch = async (
  token,
  bookingId,
  shopId,
  therapyId,
  dayId
) => {
  try {
    const queryRoute = "/timeSlot/getTimeSlotSuggestions";

    const reqBody = { bookingId, shopId, therapyId, dayId };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
