// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardTableStyles } from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function TimeSlotBox(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // States
    index,
    item,
  } = props;

  return (
    <div>
      <div className={classes.headCellTimeSlotBoxWithoutPointer}>
        {index % 6 === 0 && (
          <Typography align={"left"} color={null} variant="body1">
            {item.start_time}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default TimeSlotBox;
