// Components
import StyledMenu from "../styledMenu";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

function StaffActionMenu(props) {
  // Props
  const {
    // States
    showStaffActionMenu,
    // Set States
    setShowStaffActionMenu,
    // Functions
    displayStaffActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowStaffActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Logout":
        displayStaffActionDialog("Logout");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showStaffActionMenu}
      open={Boolean(showStaffActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Logout")}>
        <LogoutRoundedIcon />
        登出
      </MenuItem>
    </StyledMenu>
  );
}

export default StaffActionMenu;
