// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { bookingsPageReducers } from "./pages/bookingsPage/bookingsPageReducers";
import { createBookingPageReducers } from "./pages/createBookingPage/createBookingPageReducers";
import { createPatientPageReducers } from "./pages/createPatientPage/createPatientPageReducers";
import { patientsPageReducers } from "./pages/patientsPage/patientsPageReducers";
import { patientProfilePageReducers } from "./pages/patientProfilePage/patientProfilePageReducers";
import { therapiesPageReducers } from "./pages/therapiesPage/therapiesPageReducers";
// Shared
import { drawerReducers } from "./shared/drawer/drawerReducers";
import { locationReducers } from "./shared/location/locationReducers";
import { staffReducers } from "./shared/staff/staffReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  bookingsPage: bookingsPageReducers,
  createBookingPage: createBookingPageReducers,
  createPatientPage: createPatientPageReducers,
  patientsPage: patientsPageReducers,
  patientProfilePage: patientProfilePageReducers,
  therapiesPage: therapiesPageReducers,
  // Shared
  drawer: drawerReducers,
  location: locationReducers,
  staff: staffReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
