// Actions
import { showDrawer } from "../../redux/shared/drawer/drawerActions";
import { onThemeChange } from "../../redux/shared/theme/themeActions";
import { staffLogout } from "../../redux/shared/staff/staffActions";

// Components
// Dialogs
import AlertDialog from "../dialogs/alertDialog";
// Menus
import StaffActionMenu from "../menus/appBar/staffActionMenu";

// Configs
import keyConfig from "../../configs/keyConfig";
import stylesConfig from "../../configs/stylesConfig";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";
import { getPageTitleText } from "../../helperFunctions/getPageTitleText";

// Material UI
// Components
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import Brightness7RoundedIcon from "@mui/icons-material/Brightness7Rounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  appBarContainerStyles,
  showMenuBtnStyles,
  useAppBarStyles,
} from "../../styles/componentStyles/appBarStyles/appBarStyles";

function TopAppBar() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useAppBarStyles();

  // Redux Store
  const alias = useSelector((state) => state.staff.alias);
  const deptNameDisplay = useSelector((state) => state.staff.deptNameDisplay);
  const fullNameEn = useSelector((state) => state.staff.fullNameEn);
  const previousPage = useSelector((state) => state.location.previousPage);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // States
  // Dialog
  const [staffActionDialogText, setStaffActionDialogText] = useState("");
  const [staffActionDialogType, setStaffActionDialogType] = useState(null);
  const [showStaffActionDialog, setShowStaffActionDialog] = useState(false);
  // Menu
  const [showStaffActionMenu, setShowStaffActionMenu] = useState(null);

  // Handle States
  const previousPageTitleText = getPageTitleText(previousPage);

  // Events
  // Events - Btns
  const onBackBtnClicked = () => {
    const path = getPagePath(previousPage);

    // Navigate
    navigate(path);
  };

  const onShowDrawerBtnClicked = () => {
    // Update Redux Store
    dispatch(showDrawer("left"));
  };

  const onToggleThemeBtnClicked = () => {
    const themeModeVar = themeMode === "light" ? "dark" : "light";

    // Update Redux Store
    dispatch(onThemeChange(themeModeVar));

    storeThemeModeToStorage(themeModeVar);
  };

  // Events - Dialogs
  const onStaffActionDialogCanceled = () => {
    // Set States
    setShowStaffActionDialog(false);
  };

  const onStaffActionDialogConfirmed = () => {
    switch (staffActionDialogType) {
      case "Logout":
        // Update Redux Store
        dispatch(staffLogout());

        // Local Storage
        localStorage.removeItem(keyConfig.tokenKey);
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(false);
  };

  // Events - Menu
  const onShowStaffActionMenuBtnClicked = (currentTarget) => {
    // Set States
    setShowStaffActionMenu(currentTarget);
  };

  // Functions
  // Functions - Normal
  const displayStaffActionDialog = (staffActionType) => {
    // Set States
    setStaffActionDialogType(staffActionType);

    switch (staffActionType) {
      case "Logout":
        setStaffActionDialogText("確認要 登出 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowStaffActionDialog(true);
  };

  const storeThemeModeToStorage = (themeModeVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.themeKey, themeModeVar);
  };

  return (
    <AppBar
      sx={{
        ...appBarContainerStyles,
        backgroundColor:
          themeMode === "light"
            ? stylesConfig.appBarContainerBackgroundColorLight
            : stylesConfig.appBarContainerBackgroundColorDark,
      }}
    >
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onStaffActionDialogCanceled}
        onDialogConfirmed={onStaffActionDialogConfirmed}
        // States
        dialogText={staffActionDialogText}
        showDialog={showStaffActionDialog}
      />
      {/* Menu */}
      <StaffActionMenu
        // States
        showStaffActionMenu={showStaffActionMenu}
        // Set States
        setShowStaffActionMenu={setShowStaffActionMenu}
        // Functions
        displayStaffActionDialog={displayStaffActionDialog}
      />
      <Toolbar className={classes.contentContainer} disableGutters>
        <div className={classes.rightContainer}>
          <Tooltip sx={showMenuBtnStyles} title={"顯示選單"}>
            <IconButton onClick={onShowDrawerBtnClicked}>
              <MenuRoundedIcon />
            </IconButton>
          </Tooltip>
          {previousPage ? (
            <Button
              onClick={onBackBtnClicked}
              startIcon={<ArrowBackIosRoundedIcon />}
              variant="text"
            >
              {previousPageTitleText}
            </Button>
          ) : (
            <div className={classes.spaceHolder} />
          )}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={
                themeMode === "light" ? "設換至夜間模式" : "設換至日間模式"
              }
            >
              <IconButton onClick={onToggleThemeBtnClicked}>
                {themeMode === "light" ? (
                  <Brightness4RoundedIcon />
                ) : (
                  <Brightness7RoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={classes.selfInfoContainer}
            onClick={(event) =>
              onShowStaffActionMenuBtnClicked(event.currentTarget)
            }
          >
            <div className={classes.selfInfoContentContainer}>
              {alias ? (
                <Typography align={"center"} sx={{ color: "primary.main" }}>
                  {alias}
                </Typography>
              ) : (
                <Typography align={"center"} sx={{ color: "primary.main" }}>
                  {fullNameEn}
                </Typography>
              )}
              {deptNameDisplay && (
                <Typography
                  align={"center"}
                  sx={{ color: stylesConfig.greyTextColor }}
                >
                  {deptNameDisplay}
                </Typography>
              )}
            </div>
            <IconButton>
              <KeyboardArrowDownRoundedIcon />
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
