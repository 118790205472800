// Components
import StyledMenu from "../styledMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// Styles
import {
  roomToTherapyActionMenuItemLeftText,
  useStandardTableStyles,
} from "../../../styles/componentStyles/tableStyles/standardTableStyles";

function TimeSlotToRoomActionMenu(props) {
  // Hooks
  // Styles
  const classes = useStandardTableStyles();

  // Props
  const {
    // Events
    onMenuClosed,
    onMenuItemClicked,
    // States
    selectedRoomCapableTherapyIdsArr,
    showTimeSlotToRoomActionMenu,
    therapies,
  } = props;

  // Handle States
  const availableTherapies = therapies.filter((item) =>
    selectedRoomCapableTherapyIdsArr.includes(item.id)
  );

  return (
    <StyledMenu
      // States
      anchorEl={showTimeSlotToRoomActionMenu}
      open={Boolean(showTimeSlotToRoomActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {availableTherapies && availableTherapies[0] ? (
        availableTherapies.map((item) => (
          <MenuItem
            disableRipple
            key={item.id}
            onClick={() => onMenuItemClicked(item.id)}
          >
            <div className={classes.menuItem}>
              <Typography
                align={"center"}
                variant="body1"
                sx={roomToTherapyActionMenuItemLeftText}
              >
                {item.therapy_name}
              </Typography>
              <Typography
                align={"center"}
                color={stylesConfig.greyTextColor}
                variant="body2"
              >
                {`${item.duration} 分鐘`}
              </Typography>
            </div>
          </MenuItem>
        ))
      ) : (
        <MenuItem disableRipple>
          <div className={classes.menuItem}>
            <Typography
              align={"center"}
              variant="body1"
              sx={roomToTherapyActionMenuItemLeftText}
            >
              此房間未有可進行療程
            </Typography>
          </div>
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default TimeSlotToRoomActionMenu;
