// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CandidateInfoFormBasicInfoContent(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    dateBirth,
    genderTypeField,
    genderTypeOptions,
    hkid,
    fullNameCh,
    fullNameEn,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const dateBirthVar = dateBirth ? new Date(dateBirth) : null;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            基本資料
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("fullNameCh")}
            fullWidth
            helperText={getErrorFieldMessage("fullNameCh")}
            label="中文名稱"
            margin="dense"
            name="fullNameCh"
            onChange={(event) =>
              onInputFieldChange("fullNameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={fullNameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("fullNameEn")}
            fullWidth
            helperText={getErrorFieldMessage("fullNameEn")}
            label="英文全名"
            margin="dense"
            name="fullNameEn"
            onChange={(event) =>
              onInputFieldChange("fullNameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={fullNameEn}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={12} lg={6}>
          {genderTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.gender_type_name_ch}
              onChange={(event, value) =>
                onInputFieldChange("genderTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={genderTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("genderTypeField")}
                  helperText={getErrorFieldMessage("genderTypeField")}
                  label="性別"
                  variant="standard"
                />
              )}
              value={genderTypeField}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("hkid")}
            fullWidth
            helperText={getErrorFieldMessage("hkid")}
            label="身分證編號"
            margin="dense"
            name="hkid"
            onChange={(event) => onInputFieldChange("hkid", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={hkid}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.formDatePickerContainer}>
          <DatePicker
            format="dd/MM/yyyy"
            label="出生日期 (日/月/年)"
            onChange={(value) => onInputFieldChange("dateBirth", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("dateBirth"),
                helperText: getErrorFieldMessage("dateBirth"),
                variant: "standard",
              },
            }}
            value={dateBirthVar}
            views={["year", "month", "day"]}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default CandidateInfoFormBasicInfoContent;
