// Actions
import {
  onBrandChange as onBookingsPageBrandChange,
  onDateChange as onBookingsPageDateChange,
  onShopChange as onBookingsPageShopChange,
} from "../../../redux/pages/bookingsPage/bookingsPageActions";
import {
  onAreaChange,
  onAreaItemChange,
  onBrandChange as onCreateBookingPageBrandChange,
  onDateChange as onCreateBookingPageDateChange,
  onDayChange,
  onDistrictChange,
  onDistrictItemChange,
  onRoomChange,
  onShopChange as onCreateBookingPageShopChange,
  onTherapyChange,
  onTherapyItemChange,
  onTimeSlotChange,
} from "../../../redux/pages/createBookingPage/createBookingPageActions";

// Components
// Boxes
import DaySuggestionBoxesContainer from "./daySuggestionBoxesContainer";
import RoomSuggestionBoxesContainer from "./roomSuggestionBoxesContainer";
import ShopSuggestionBoxesContainer from "./shopSuggestionBoxesContainer";
import TimeSlotSuggestionBoxesContainer from "./timeSlotSuggestionBoxesContainer";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SearchPatientModal from "../../modals/search/searchPatientModal";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import {
  createBookingFetch,
  editBookingFetch,
} from "../../../fetches/bookingFetches";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function ContentContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // States
    bookingIdParam,
  } = props;

  // Redux Store
  const brandId = useSelector((state) => state.createBookingPage.brandId);
  const date = useSelector((state) => state.createBookingPage.date);
  const dayId = useSelector((state) => state.createBookingPage.dayId);
  const districtId = useSelector((state) => state.createBookingPage.districtId);
  const roomId = useSelector((state) => state.createBookingPage.roomId);
  const shopId = useSelector((state) => state.createBookingPage.shopId);
  const therapyId = useSelector((state) => state.createBookingPage.therapyId);
  const timeSlotId = useSelector((state) => state.createBookingPage.timeSlotId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Dialog
  const [bookingActionDialogText, setBookingActionDialogText] = useState("");
  const [bookingActionDialogType, setBookingActionDialogType] = useState(null);
  const [showBookingActionDialog, setShowBookingActionDialog] = useState(false);
  // Modals
  const [showSearchPatientModal, setShowSearchPatientModal] = useState(false);
  // Snackbar
  const [showBookingActionSnackbar, setShowBookingActionSnackbar] =
    useState(false);
  const [bookingActionSnackbarText, setBookingActionSnackbarText] =
    useState("");
  const [bookingActionSnackbarType, setBookingActionSnackbarType] =
    useState("success");

  // Handle States
  const shouldShowShopSuggestions = therapyId && districtId;
  const shouldShowDaySuggestions = shouldShowShopSuggestions && shopId;
  const shouldShowTimeSlotSuggestions = shouldShowDaySuggestions && dayId;
  const shouldShowRoomSuggestions = shouldShowTimeSlotSuggestions && timeSlotId;

  // Events
  // Events - Dialogs
  const onBookingActionDialogCanceled = () => {
    // Set States
    setShowBookingActionDialog(false);
  };

  const onBookingActionDialogConfirmed = () => {
    switch (bookingActionDialogType) {
      case "EditBooking":
        editBooking();
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(false);
  };

  // Events - Modal
  const onSearchPatientModalItemClicked = (itemId) => {
    createBooking(itemId);

    onSearchPatientModalClosed();
  };

  const onSearchPatientModalClosed = () => {
    clearAll();

    // Set States
    setShowSearchPatientModal(false);
  };

  // Events - Snackbar
  const onBookingActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowBookingActionSnackbar(false);
  };

  // Functions
  // Functions - Normal
  const clearAll = () => {
    // Update Redux Store
    dispatch(onAreaChange(null));
    dispatch(onAreaItemChange(null));
    dispatch(onCreateBookingPageBrandChange(null));
    dispatch(onCreateBookingPageDateChange(null));
    dispatch(onDayChange(null));
    dispatch(onDistrictChange(null));
    dispatch(onDistrictItemChange(null));
    dispatch(onRoomChange(null));
    dispatch(onCreateBookingPageShopChange(null));
    dispatch(onTherapyChange(null));
    dispatch(onTherapyItemChange(null));
    dispatch(onTimeSlotChange(null));
  };

  const displayBookingActionDialog = (bookingActionType) => {
    // Set States
    setBookingActionDialogType(bookingActionType);

    switch (bookingActionType) {
      case "EditBooking":
        setBookingActionDialogText("確認要修改 預約 嗎？");
        break;
      default:
        break;
    }

    // Set States
    setShowBookingActionDialog(true);
  };

  const displayBookingActionSnackBar = (bookingActionMessage) => {
    // Set States
    setBookingActionSnackbarText(bookingActionMessage);
    setBookingActionSnackbarType("warning");

    setShowBookingActionSnackbar(true);
  };

  const displaySearchPatientModal = () => {
    // Set States
    setShowSearchPatientModal(true);
  };

  // Functions - Mutations
  const createBooking = async (patientId) => {
    const results = await createBookingFetch(
      token,
      roomId,
      patientId,
      therapyId,
      date,
      timeSlotId
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onBookingsPageBrandChange(brandId));
      dispatch(onBookingsPageDateChange(date));
      dispatch(onBookingsPageShopChange(shopId));

      clearAll();

      // Navigate
      navigate("/bookings");
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  const editBooking = async () => {
    const results = await editBookingFetch(
      token,
      bookingIdParam ? bookingIdParam : null,
      roomId,
      therapyId,
      date,
      timeSlotId
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onBookingsPageBrandChange(brandId));
      dispatch(onBookingsPageDateChange(date));
      dispatch(onBookingsPageShopChange(shopId));

      clearAll();

      // Navigate
      navigate("/bookings");
    } else if (
      results.isValidate !== undefined &&
      results.isValidate === false
    ) {
      displayBookingActionSnackBar(results.message);
    }
  };

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onBookingActionDialogCanceled}
        onDialogConfirmed={onBookingActionDialogConfirmed}
        // States
        dialogText={bookingActionDialogText}
        showDialog={showBookingActionDialog}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onSearchPatientModalClosed}
        // States
        showModal={showSearchPatientModal}
      >
        <SearchPatientModal
          // Events
          onItemClicked={onSearchPatientModalItemClicked}
        />
      </ModalContainer>
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onBookingActionSnackbarClosed}
        // States
        showSnackbar={showBookingActionSnackbar}
        snackbarText={bookingActionSnackbarText}
        snackbarType={bookingActionSnackbarType}
      />
      {shouldShowShopSuggestions && (
        <>
          <ShopSuggestionBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
      {shouldShowDaySuggestions && (
        <>
          <DaySuggestionBoxesContainer
            // States
            bookingIdParam={bookingIdParam}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
      {shouldShowTimeSlotSuggestions && (
        <>
          <TimeSlotSuggestionBoxesContainer
            // States
            bookingIdParam={bookingIdParam}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
      {shouldShowRoomSuggestions && (
        <>
          <RoomSuggestionBoxesContainer
            // States
            bookingIdParam={bookingIdParam}
            // Functions
            displayBookingActionDialog={displayBookingActionDialog}
            displaySearchPatientModal={displaySearchPatientModal}
          />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </>
      )}
    </>
  );
}

export default ContentContainer;
