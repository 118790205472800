// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/therapiesPage/therapiesPageActions";

// Components
// Boxes
import RoomBoxesContainer from "./roomBoxesContainer";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";
// Tables
import RoomToTherapyTableContainer from "../../tables/therapiessPage/roomToTherapyTableContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Fetches
import { createOrEditRoomToTherapiesFetch } from "../../../fetches/roomToTherapyFetches";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function RoomContentContainer() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Hover
  const [hoveredRoomId, setHoveredRoomId] = useState(null);

  // Select Mode
  const [editedRoomToTherapies, setEditedRoomToTherapies] = useState([]);
  const [selectedRoomToTherapies, setSelectedRoomToTherapies] = useState([]);

  // Functions
  // Functions - Mutations
  const createOrEditRoomToTherapies = async (isCapable) => {
    const results = await createOrEditRoomToTherapiesFetch(
      token,
      selectedRoomToTherapies,
      isCapable
    );

    if (results.success) {
      // Set States
      setSelectedRoomToTherapies([]);
      setEditedRoomToTherapies((currentState) => [
        ...currentState,
        ...selectedRoomToTherapies,
      ]);

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  return (
    <>
      <RoomBoxesContainer
        // States
        hoveredRoomId={hoveredRoomId}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <RoomToTherapyTableContainer
        // States
        editedRoomToTherapies={editedRoomToTherapies}
        hoveredRoomId={hoveredRoomId}
        selectedRoomToTherapies={selectedRoomToTherapies}
        // Set States
        setEditedRoomToTherapies={setEditedRoomToTherapies}
        setHoveredRoomId={setHoveredRoomId}
        setSelectedRoomToTherapies={setSelectedRoomToTherapies}
        // Functions
        createOrEditRoomToTherapies={createOrEditRoomToTherapies}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
    </>
  );
}

export default RoomContentContainer;
