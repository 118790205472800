// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

// sx Styles
export const tableContainerStyles = {
  maxHeight: "50vh",
  // Disable Text Selection
  khtmlUserSelect: "none",
  mozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  webkitTouchCallout: "none",
  webkitUserSelect: "none",
};

export const roomToTherapyActionMenuItemLeftText = {
  marginRight: "20px",
};

export const useStandardTableStyles = makeStyles((theme) =>
  createStyles({
    // Cell Box
    cellBoxLarge: {
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    cellBoxLargeWithoutPointer: {
      width: 120,
    },
    cellBoxMedium: {
      width: 96,
      "& :hover": {
        cursor: "pointer",
      },
    },
    cellBoxMediumWithoutPointer: {
      width: 96,
    },
    cellBoxSmall: {
      height: 24,
      width: 48,
      "& :hover": {
        cursor: "pointer",
      },
    },
    cellBoxSmallWithoutPointer: {
      height: 24,
      width: 48,
    },
    cellBoxTimeSlot: {
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    cellBoxTimeSlotWithoutPointer: {
      height: stylesConfig.timeSlotBoxHeight,
      width: 120,
    },
    cellBoxTiny: {
      height: 24,
      width: 24,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTiny: {
      borderColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      borderStyle: "solid",
      borderWidth: 2,
      height: 24,
      marginBottom: 4,
      width: 24,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxMedium: {
      borderColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      borderStyle: "solid",
      borderWidth: 2,
      height: 24,
      marginBottom: 4,
      width: 96,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxLarge: {
      borderColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      borderStyle: "solid",
      borderWidth: 2,
      height: 24,
      marginBottom: 4,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotBottom: {
      backgroundColor: stylesConfig.blueTextColor,
      borderBottomLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderBottomRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotBottomCompleted: {
      backgroundColor: stylesConfig.greenTextColor,
      borderBottomLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderBottomRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotBottomForbidden: {
      backgroundColor: stylesConfig.redTextColor,
      borderBottomLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderBottomRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotMiddle: {
      backgroundColor: stylesConfig.blueTextColor,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotMiddleCompleted: {
      backgroundColor: stylesConfig.greenTextColor,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotMiddleForbidden: {
      backgroundColor: stylesConfig.redTextColor,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotSingle: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightSingle,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotSingleCompleted: {
      backgroundColor: stylesConfig.greenTextColor,
      borderRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightSingle,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotSingleForbidden: {
      backgroundColor: stylesConfig.redTextColor,
      borderRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightSingle,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotTop: {
      backgroundColor: stylesConfig.blueTextColor,
      borderTopLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderTopRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotTopCompleted: {
      backgroundColor: stylesConfig.greenTextColor,
      borderTopLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderTopRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoveredCellBoxTimeSlotTopForbidden: {
      backgroundColor: stylesConfig.redTextColor,
      borderTopLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderTopRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    selectedCellBoxLarge: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      width: 120,
    },
    selectedCellBoxMedium: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      width: 96,
    },
    selectedCellBoxSmallWithoutPointer: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      height: 24,
      width: 48,
    },
    selectedCellBoxTimeSlotBottom: {
      backgroundColor: stylesConfig.yellowTextColor,
      borderBottomLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderBottomRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    selectedCellBoxTimeSlotMiddle: {
      backgroundColor: stylesConfig.yellowTextColor,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    selectedCellBoxTimeSlotSingle: {
      backgroundColor: stylesConfig.yellowTextColor,
      borderRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightSingle,
      justifyContent: "center",
      marginBottom: stylesConfig.timeSlotBoxMargin,
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    selectedCellBoxTimeSlotTop: {
      backgroundColor: stylesConfig.yellowTextColor,
      borderTopLeftRadius: stylesConfig.timeSlotBoxBorderRadius,
      borderTopRightRadius: stylesConfig.timeSlotBoxBorderRadius,
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeightMargin,
      justifyContent: "center",
      marginTop: stylesConfig.timeSlotBoxMargin,
      width: 120,
      "& :hover": {
        cursor: "pointer",
      },
    },
    selectedCellBoxTiny: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: 100,
      height: 24,
      width: 24,
      "& :hover": {
        cursor: "pointer",
      },
    },
    // Column
    stickyColumn: {
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      left: 0,
      position: "sticky",
    },
    stickyColumnHeader: {
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroudColorLight
          : stylesConfig.contentBackgroudColorDark,
      left: 0,
      position: "sticky",
      zIndex: 3,
    },
    // Field Box
    fieldBoxWithPointer: {
      "& :hover": {
        cursor: "pointer",
      },
    },
    // Head Coulmn Cell
    selectedHeadCellBox: {
      backgroundColor: stylesConfig.blueTextColor,
      borderRadius: 10,
      color: stylesConfig.whiteTextColor,
      minWidth: 150,
      padding: "0px 10px",
    },
    headCellBox: {
      minWidth: 150,
      padding: "0px 10px",
      "& :hover": {
        cursor: "pointer",
      },
    },
    headCellBoxWithoutPointer: {
      minWidth: 150,
      padding: "0px 10px",
    },
    headCellTimeSlotBoxWithoutPointer: {
      display: "flex",
      flexDirection: "column",
      height: stylesConfig.timeSlotBoxHeight,
      justifyContent: "center",
      minWidth: 150,
      padding: "0px 10px",
    },
    // Menu
    menuItem: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    // Table
    timeTable: {
      "& .MuiTableCell-root": {
        padding: "0px 16px",
      },
    },
  })
);
