export const getTimeSlotBoxStylesType = (
  bookingId,
  bookingToTimeSlotTypeId,
  isBookingSelected,
  isHoveredRoomCapable,
  isSelected,
  isHovered,
  isBottom,
  isTop
) => {
  if (isHovered && !isHoveredRoomCapable) {
    if (isBottom && isTop) {
      return { type: "Single", isSelected: false, isForbidden: true };
    } else if (isBottom) {
      return { type: "Bottom", isSelected: false, isForbidden: true };
    } else if (isTop) {
      return { type: "Top", isSelected: false, isForbidden: true };
    } else {
      return { type: "Middle", isSelected: false, isForbidden: true };
    }
  }

  if (bookingId && isHovered) {
    if (isBottom && isTop) {
      return {
        type: "Single",
        isSelected: false,
        isForbidden: !isBookingSelected,
      };
    } else if (isBottom) {
      return {
        type: "Bottom",
        isSelected: false,
        isForbidden: !isBookingSelected,
      };
    } else if (isTop) {
      return {
        type: "Top",
        isSelected: false,
        isForbidden: !isBookingSelected,
      };
    } else {
      return {
        type: "Middle",
        isSelected: false,
        isForbidden: !isBookingSelected,
      };
    }
  }

  if (bookingId) {
    switch (bookingToTimeSlotTypeId) {
      // Top
      case 1:
        return {
          type: "Top",
          isSelected: isBookingSelected,
          isForbidden: false,
        };
      // Middle
      case 2:
        return {
          type: "Middle",
          isSelected: isBookingSelected,
          isForbidden: false,
        };
      // Bottom
      case 3:
        return {
          type: "Bottom",
          isSelected: isBookingSelected,
          isForbidden: false,
        };
      // Single
      case 4:
        return {
          type: "Single",
          isSelected: isBookingSelected,
          isForbidden: false,
        };
      default:
        return null;
    }
  }

  if (isSelected) {
    return { type: "Single", isSelected: false, isForbidden: false };
  }

  if (isHovered) {
    if (isBottom && isTop) {
      return { type: "Single", isSelected: false, isForbidden: false };
    } else if (isBottom) {
      return { type: "Bottom", isSelected: false, isForbidden: false };
    } else if (isTop) {
      return { type: "Top", isSelected: false, isForbidden: false };
    } else {
      return { type: "Middle", isSelected: false, isForbidden: false };
    }
  }

  return null;
};
