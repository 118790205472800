// Actions
import {
  onDateChange,
  onHandledByChange,
} from "../../../redux/pages/bookingsPage/bookingsPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Modlas
import ModalContainer from "../../modals/modalContainer";
import HandledByActionModal from "../../modals/bookingsPage/handledByActionModal";
// Tables
import TimeSlotToRoomTable from "./timeSlotToRoomTable";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Debounce
import debounce from "lodash.debounce";

// Dummy Datum
import { handledByDummyData } from "../../../dummyDatum/handledByDummyData";

// Fetches
import { getAllBookingsWithRoomsByShopIdAndDateFetch } from "../../../fetches/bookingFetches";
import { getAllTherapiesByShopIdFetch } from "../../../fetches/therapyFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// Icons
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// Moment
import moment from "moment";
import "moment/locale/zh-hk";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();

function TimeSlotToRoomTableContainer(props) {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    bookingActionSnackbarText,
    bookingActionSnackbarType,
    hoveredRoomId,
    hoveredRoomCapableTherapyIdsArr,
    isEditMode,
    numOfAffectedTimeSlots,
    selectedBookingId,
    selectedBookingStatusTypeId,
    selectedRoomId,
    selectedRoomCapableTherapyIdsArr,
    selectedTherapyId,
    selectedTimeSlotId,
    showBookingActionSnackbar,
    // Set States
    setHoveredRoomId,
    setHoveredRoomCapableTherapyIdsArr,
    setIsEditMode,
    setNumOfAffectedTimeSlots,
    setSelectedBookingId,
    setSelectedBookingStatusTypeId,
    setSelectedRoomId,
    setSelectedRoomCapableTherapyIdsArr,
    setSelectedTherapyId,
    setSelectedTimeSlotId,
    // Events
    onBookingActionSnackbarClosed,
    // Functions
    cancelBooking,
    clearAll,
    clearSelectedTherapy,
    createBooking,
    editBooking,
    validateBooking,
  } = props;

  // Redux Store
  const date = useSelector((state) => state.bookingsPage.date);
  const formSubmitCount = useSelector(
    (state) => state.bookingsPage.formSubmitCount
  );
  const handledById = useSelector((state) => state.bookingsPage.handledById);
  const handledByDisplayText = useSelector(
    (state) => state.bookingsPage.handledByDisplayText
  );
  const shopId = useSelector((state) => state.bookingsPage.shopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [rooms, setRooms] = useState(null);
  const [therapies, setTherapies] = useState(null);
  const [timeSlotWithBookings, setTimeSlotWithBookings] = useState(null);
  // Modals
  const [showHandledByActionModal, setShowHandledByActionModal] =
    useState(false);
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const dateVar = date ? new Date(date) : null;

  const isDateVarEqualsToday =
    dateVar.getFullYear() === today.getFullYear() &&
    dateVar.getMonth() === today.getMonth() &&
    dateVar.getDate() === today.getDate();

  const isHoveredRoomCapable =
    hoveredRoomId && selectedTherapyId
      ? hoveredRoomCapableTherapyIdsArr.includes(selectedTherapyId)
      : true;

  // Events
  // Events - Fields
  const onDateBackAndForwardBtnClicked = (actionType) => {
    const newDateVar =
      actionType === "back"
        ? new Date(
            dateVar.getFullYear(),
            dateVar.getMonth(),
            dateVar.getDate() - 1
          )
        : new Date(
            dateVar.getFullYear(),
            dateVar.getMonth(),
            dateVar.getDate() + 1
          );

    // Update Redux Store
    dispatch(onDateChange(newDateVar.toString()));
  };

  const onTableDateChange = (value) => {
    // Update Redux Store
    dispatch(onDateChange(value.toString()));
  };

  // Events - Modal
  const onHandledByActionModalClosed = () => {
    // Set States
    setShowHandledByActionModal(false);
  };

  // Events - Title
  const onChangeHandledByBtnClicked = () => {
    displayHandledByActionActionModal();
  };

  const onClearSelectedBookingBtnClicked = () => {
    clearAll();
  };

  const onClearSelectedTherapyBtnClicked = () => {
    clearSelectedTherapy();
  };

  const onReturnToTodayBtnClicked = () => {
    // Update Redux Store
    dispatch(onDateChange(today.toString()));
  };

  const onSuggestBookingBtnClicked = () => {
    // Navigate
    navigate("/createBooking");
  };

  // Functions
  // Functions - Normal
  const displayHandledByActionActionModal = () => {
    setShowHandledByActionModal(true);
  };

  const getAllBookingsWithRoomsDebounce = debounce(
    (shopIdVar, dateVar, handledByIdVar) => {
      getAllBookingsWithRoomsByShopIdAndDate(
        shopIdVar,
        dateVar,
        handledByIdVar
      );
    },
    timeoutConfig.debouceTime
  );

  const resetHandledBy = () => {
    // Update Redux Store
    dispatch(
      onHandledByChange(
        handledByDummyData[0].id,
        handledByDummyData[0].full_name_en
      )
    );
  };

  // Functions - Queries
  const getAllBookingsWithRoomsByShopIdAndDate = async (
    shopIdVar,
    dateVar,
    handledByIdVar
  ) => {
    const results = await getAllBookingsWithRoomsByShopIdAndDateFetch(
      token,
      shopIdVar,
      dateVar,
      handledByIdVar
    );

    if (results.timeSlotWithBookings) {
      for (let timeSlotItem of results.timeSlotWithBookings) {
        timeSlotItem.start_time = timeSlotItem.start_time
          ? moment(timeSlotItem.start_time).format("a h:mm")
          : null;

        if (timeSlotItem.timeSlotToRooms) {
          for (let timeSlotToRoomItem of timeSlotItem.timeSlotToRooms) {
            timeSlotToRoomItem.start_time = timeSlotToRoomItem.start_time
              ? moment(timeSlotToRoomItem.start_time).format("a h:mm")
              : null;

            timeSlotToRoomItem.end_time = timeSlotToRoomItem.end_time
              ? moment(timeSlotToRoomItem.end_time).format("a h:mm")
              : null;
          }
        }
      }
    }

    // Set States
    setRooms(results.rooms ? results.rooms : null);
    setTimeSlotWithBookings(
      results.timeSlotWithBookings ? results.timeSlotWithBookings : null
    );

    setIsLoading(false);
  };

  const getAllTherapiesByShopId = async () => {
    const results = await getAllTherapiesByShopIdFetch(
      token,
      shopId,
      "Current"
    );

    // Set States
    setTherapies(results.therapies ? results.therapies : null);
  };

  // Life Cycle
  useEffect(() => {
    if (shopId) {
      getAllTherapiesByShopId();
    }

    resetHandledBy();
  }, [shopId]);

  useEffect(() => {
    if (shopId) {
      // Set States
      setIsLoading(true);

      getAllBookingsWithRoomsDebounce(shopId, date, handledById);
    }

    if (!isEditMode) {
      clearAll();
    }
  }, [shopId, date, handledById]);

  useEffect(() => {
    if (shopId) {
      // Set States
      setIsLoading(true);

      getAllBookingsWithRoomsDebounce(shopId, date, handledById);
    }

    clearAll();
  }, [formSubmitCount]);

  return (
    <div className={classes.contentBoxWithMaxWidth}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onHandledByActionModalClosed}
        // States
        showModal={showHandledByActionModal}
      >
        <HandledByActionModal
          // Events
          onModalClosed={onHandledByActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            預約
          </Typography>
          <div className={classes.formTwinIconBtnContainerRight}>
            {selectedTherapyId && !selectedBookingId ? (
              <Button
                endIcon={<ClearRoundedIcon />}
                onClick={onClearSelectedTherapyBtnClicked}
                variant="text"
              >
                取消選擇療程
              </Button>
            ) : selectedBookingId && isEditMode ? (
              <Button
                endIcon={<ClearRoundedIcon />}
                onClick={onClearSelectedBookingBtnClicked}
                variant="text"
              >
                取消選擇預約
              </Button>
            ) : (
              <Button
                endIcon={<ManageSearchRoundedIcon />}
                onClick={onSuggestBookingBtnClicked}
                variant="text"
              >
                預約建議
              </Button>
            )}
          </div>
        </div>
        {/* Date Picker */}
        <div className={classes.titleTextContainer}>
          <div className={classes.formTwinIconBtnContainerLeft}>
            <Button
              onClick={onChangeHandledByBtnClicked}
              startIcon={<AccountCircleRoundedIcon />}
              variant="text"
            >
              {handledByDisplayText}
            </Button>
            {!isDateVarEqualsToday && (
              <Button
                onClick={onReturnToTodayBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                返回今日
              </Button>
            )}
          </div>
          <div className={classes.formDatePickerContainer}>
            <DatePicker
              format="dd/MM/yyyy"
              label="日期 (日/月/年)"
              onChange={onTableDateChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={dateVar}
              views={["year", "month", "day"]}
            />
          </div>
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onDateBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onDateBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {rooms && timeSlotWithBookings ? (
        // Table
        <TimeSlotToRoomTable
          // States
          bookingActionSnackbarText={bookingActionSnackbarText}
          bookingActionSnackbarType={bookingActionSnackbarType}
          hoveredRoomId={hoveredRoomId}
          isEditMode={isEditMode}
          isHoveredRoomCapable={isHoveredRoomCapable}
          numOfAffectedTimeSlots={numOfAffectedTimeSlots}
          rooms={rooms}
          selectedBookingId={selectedBookingId}
          selectedBookingStatusTypeId={selectedBookingStatusTypeId}
          selectedRoomId={selectedRoomId}
          selectedRoomCapableTherapyIdsArr={selectedRoomCapableTherapyIdsArr}
          selectedTherapyId={selectedTherapyId}
          selectedTimeSlotId={selectedTimeSlotId}
          showBookingActionSnackbar={showBookingActionSnackbar}
          therapies={therapies}
          timeSlotWithBookings={timeSlotWithBookings}
          // Set States
          setHoveredRoomId={setHoveredRoomId}
          setHoveredRoomCapableTherapyIdsArr={
            setHoveredRoomCapableTherapyIdsArr
          }
          setIsEditMode={setIsEditMode}
          setNumOfAffectedTimeSlots={setNumOfAffectedTimeSlots}
          setSelectedBookingId={setSelectedBookingId}
          setSelectedBookingStatusTypeId={setSelectedBookingStatusTypeId}
          setSelectedRoomId={setSelectedRoomId}
          setSelectedRoomCapableTherapyIdsArr={
            setSelectedRoomCapableTherapyIdsArr
          }
          setSelectedTherapyId={setSelectedTherapyId}
          setSelectedTimeSlotId={setSelectedTimeSlotId}
          // Events
          onBookingActionSnackbarClosed={onBookingActionSnackbarClosed}
          // Funtions
          cancelBooking={cancelBooking}
          clearAll={clearAll}
          createBooking={createBooking}
          editBooking={editBooking}
          validateBooking={validateBooking}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            未有預約資料
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TimeSlotToRoomTableContainer;
