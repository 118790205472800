// Actions
import { onActiveStepReset } from "../redux/pages/createPatientPage/createPatientPageActions";
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Forms
import CreatePatientFormContainer from "../components/forms/createPatientPage/createPatientFormContainer";
// Headers
import CreatePatientPageHeader from "../components/headers/createPatientPage/createPatientPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function CreatePatientPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(onActiveStepReset());
    dispatch(enterPage("CreatePatient"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <CreatePatientPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <CreatePatientFormContainer />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Container>
  );
}

export default CreatePatientPage;
