export const getPageTitleText = (titleType) => {
  let titleText;

  switch (titleType) {
    case "Bookings":
      titleText = "預約";
      break;
    case "CreateBooking":
      titleText = "新增預約";
      break;
    case "CreatePatient":
      titleText = "新增病人";
      break;
    case "Patients":
      titleText = "病人";
      break;
    case "PatientProfile":
      titleText = "病人概覧";
      break;
    case "Therapies":
      titleText = "療程";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
